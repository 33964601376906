import styled from "styled-components";

export const StyledLocationImageUploadContainer = styled.div`
cursor: pointer;
position: relative;

.upload-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  background: #4b434357;
  opacity: 0;
  border-radius: 5px;

  &:hover {
    opacity: 1;
  }
}

.lazy-load-image-background {
  width: 100%;
  height: 100%;
}
`;