import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import CustomModal from "../CustomModal";
import {
  DetailWrapper,
  IconsWrapper,
  ImageWrapper,
  InfoWrapper,
  StyledTbFocusCentered,
  StyledBiInfoCircle,
  StyledIoMdDownload,
  ProgressBarWrapper,
  StyledBiSolidArchiveIn,
  StyledBiSolidArchiveOut,
} from "./ThumbnailModal.style";
import { Image } from "../../image/Image";
import { ThumbnailCard } from "../../image-card/thumbnail-card/ThumbnailCard";
import { Header } from "../../image-card/thumbnail-card/ThumbnailCard.style";
import { Typography } from "../../typrography/Typography";
import CircleProgressBar from "../../circle-progress-bar/CircleProgressBar";
import GaugePlotBar from "../../circle-progress-bar/GaugePlotBar";
import { ICaptureImageCard } from "../../../interfaces/cardTypes/captures";
import { useAppDispatch } from "../../../store/hooks";
import {
  archiveCapture,
  handleDownloadCapture,
} from "../../../services/captures/capture.service";
import { Tooltip } from "react-tooltip";
import ConfirmationModal from "../confirmation-modal/ConfirmationModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CustomIconButton } from "../../button/icon-button/CustomIconButton";

export interface ThumbnailModalProps {
  isOpen: boolean;
  onClose: () => void;
  isArchivedData?: boolean;
  modalWidth?: string;
  modalHeight?: string;
  activeCard: ICaptureImageCard;
}

const ThumbnailModal = (props: ThumbnailModalProps) => {
  const {
    isOpen,
    onClose,
    isArchivedData,
    modalWidth,
    modalHeight,
    activeCard,
  } = props;
  const {
    id,
    fullMedia,
    deviceId,
    device,
    modelId,
    model,
    modelDeleted,
    modelArchived,
    snapshortTakenTime,
    pairedGroup,
    gpuUsage,
    cpuUsage,
    aiConfidence,
    temperature,
    frame,
  } = activeCard;
  const options: any = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const dispatch = useAppDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState<string>("");
  const [confirmMsg, setConfirmMsg] = useState<string>("");

  const handleConfirmationModalOpen = () => {
    const actionType = isArchivedData ? "unarchive" : "archive";
    const action = isArchivedData ? "Unarchive" : "Archive";
    const msg = `Are you sure you'd like to ${actionType} the capture that capture ID is ${id}?`;

    setConfirmMsg(msg);
    setHeaderTitle(`${action} Capture`);
    setIsConfirmModalOpen(true);
    onClose(); // Close the thumbnail modal
  };

  const handleArchiveCapture = () => {
    //Archive capture and update capture states
    dispatch(
      archiveCapture({
        Id: id,
        ModelId: modelId,
        DeviceId: deviceId,
        Archive: isArchivedData ? false : true,
      }),
    )
      .then(() => {
        setIsConfirmModalOpen(false);
      })
      .catch((error) => {
        console.error("Error during dispatch:", error);
      });
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        width={modalWidth}
        height={modalHeight}
        gap="0"
      >
        <ImageWrapper>
          {frame === "Video" ? (
            // <Image src={PreSignedMedia} alt={"Capture Image"} objectFit="fill" />
            <video src={fullMedia!} controls style={{ objectFit: "contain" }} />
          ) : (
            <LazyLoadImage
              key={fullMedia!}
              src={fullMedia!}
              alt="Capture Image"
              effect="blur"
              width="100%"
              height="100%"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </ImageWrapper>
        <DetailWrapper>
          <ThumbnailCard data={device}></ThumbnailCard>
          <InfoWrapper>
            <Header>AI Model</Header>
            <Typography display="flex" direction="flex-start" variant="xs">
              {modelDeleted
                ? `${model} (Deleted)`
                : modelArchived
                ? `${model} (Archived)`
                : model}
            </Typography>
            <Header>Snapshot taken at</Header>
            <Typography display="flex" direction="flex-start" variant="xs">
              {snapshortTakenTime.toLocaleString("en-CA", options)}
            </Typography>
            <Header>Paired Group</Header>
            <Typography display="flex" direction="flex-start" variant="xs">
              {pairedGroup.length > 0
                ? pairedGroup.join(",")
                : "No paired groups"}
            </Typography>
          </InfoWrapper>
          <ProgressBarWrapper>
            {gpuUsage && gpuUsage > 0 ? (
              <CircleProgressBar
                name="GPU Usage %"
                uv={Math.round(gpuUsage * 100)}
                fill="#03916E"
                size={(window.innerHeight / 100) * 24}
                pct={gpuUsage}
              />
            ) : (
              <Typography display="flex">No Detection</Typography>
            )}

            {cpuUsage && cpuUsage > 0 ? (
              <CircleProgressBar
                name="CPU Usage %"
                uv={Math.round(cpuUsage * 100)}
                fill="#03916E"
                size={(window.innerHeight / 100) * 24}
                pct={cpuUsage}
              />
            ) : (
              <Typography display="flex">No Detection</Typography>
            )}

            {aiConfidence && aiConfidence > 0 ? (
              <CircleProgressBar
                name="AI Confidence"
                uv={Math.round(aiConfidence * 100)}
                fill="#03916E"
                size={(window.innerHeight / 100) * 24}
                pct={aiConfidence}
              />
            ) : (
              <Typography display="flex">No Detection</Typography>
            )}
            {temperature ? (
              <GaugePlotBar percent={temperature / 100} />
            ) : (
              <Typography display="flex">No Detection</Typography>
            )}
          </ProgressBarWrapper>
          <IconsWrapper>
            {isArchivedData ? (
              <CustomIconButton onClick={handleConfirmationModalOpen}>
                <StyledBiSolidArchiveOut
                  data-tooltip-id="unarchive-tooltip"
                  data-tooltip-content="Unarchive"
                  data-tooltip-place="top"
                />
                <Tooltip id="unarchive-tooltip" />
              </CustomIconButton>
            ) : (
              <CustomIconButton onClick={handleConfirmationModalOpen}>
                <StyledBiSolidArchiveIn
                  data-tooltip-id="archive-tooltip"
                  data-tooltip-content="Archive"
                  data-tooltip-place="top"
                />
                <Tooltip id="archive-tooltip" />
              </CustomIconButton>
            )}
            <CustomIconButton
              onClick={() =>
                handleDownloadCapture(activeCard.mediaPath!, dispatch)
              }
            >
              <StyledIoMdDownload
                data-tooltip-id="modal-download-tooltip"
                data-tooltip-content="Download"
              />
              <Tooltip id="modal-download-tooltip" />
            </CustomIconButton>
            <CustomIconButton>
              <StyledBiInfoCircle
                data-tooltip-id="modal-info-tooltip"
                data-tooltip-content="More Information"
                className="disabled"
              />
              <Tooltip id="modal-info-tooltip" />
            </CustomIconButton>
          </IconsWrapper>
        </DetailWrapper>
      </CustomModal>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={handleArchiveCapture}
        headerTitle={headerTitle}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
    </>
  );
};

export default ThumbnailModal;
