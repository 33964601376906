import React, { ChangeEvent, FocusEvent } from "react";
import {
  CustomInput,
  ErrorMsg,
  InputLabel,
  InputWrapper,
  SubLabel,
} from "./Input.style";
import { useField } from "formik";

export interface InputProps {
  width?: string;
  height?: string;
  label?: string;
  subLabel?: string;
  placeHolder?: string;
  field?: string;
  name: string;
  padding?: string;
  margin?: string;
  fontSize?: string;
  inputWrapperWidth?: string;
  isColoredLabel?: boolean;
  value?: string | number;
  type?: string;
  disabled?: boolean;
  alignCenter?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  hasCustomErrorMessage?: boolean;
  hasErrorMessage?: boolean;
  inputMode?:
    | "decimal"
    | "email"
    | "none"
    | "numeric"
    | "search"
    | "tel"
    | "text"
    | "url";
  wrapperPadding?: string;
}

const Input: React.FC<InputProps> = ({
  width,
  height,
  label,
  placeHolder,
  subLabel,
  name,
  padding,
  margin,
  fontSize,
  inputWrapperWidth,
  isColoredLabel,
  value,
  type,
  disabled,
  alignCenter,
  onChange,
  onBlur,
  hasCustomErrorMessage,
  inputMode,
  wrapperPadding,
  hasErrorMessage = true,
}) => {
  const [field, meta] = useField(name);

  return (
    <InputWrapper
      padding={wrapperPadding || padding}
      fontSize={fontSize}
      width={inputWrapperWidth}
    >
      {label ? (
        <InputLabel htmlFor={name} isColoredLabel={isColoredLabel}>
          {label}
          <SubLabel>{subLabel ? subLabel : ""}</SubLabel>
        </InputLabel>
      ) : (
        ""
      )}
      <CustomInput
        disabled={!!disabled}
        alignCenter={alignCenter}
        id={name}
        name={name}
        placeholder={placeHolder}
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        onChange={(e) => {
          if (onChange) onChange(e); // Call external handler if provided
          field.onChange(e); // Always call Formik's handler
        }}
        onBlur={(e) => {
          if (onBlur) onBlur(e); // Call external handler if provided
          field.onBlur(e); // Always call Formik's handler
        }}
        value={
          typeof field.value === "string"
            ? field.value.trimStart()
            : field.value
        }
        type={type}
        onKeyDown={(e) => {
          if (type === "number" && ["e", "E", "+"].includes(e.key)) {
            e.preventDefault();
          }
        }}
      />
      {hasErrorMessage && (
        <div style={{ height: fontSize || "1rem", marginTop: "10px" }}>
          {meta.touched && meta.error && !hasCustomErrorMessage && (
            <ErrorMsg style={{}}>{meta.error}</ErrorMsg>
          )}
        </div>
      )}
    </InputWrapper>
  );
};

export default Input;
