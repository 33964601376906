import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showPopupMessage } from "../../store/slices/messagePopups/messagePopupSlice";
import { createMessagePopup } from "../../utils/messagePopup";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2/company";

/**
 * Update device data threshold in the server. This is used to throttle
 * the data fetching from the device.
 *
 * @param updatedDeviceDataThreshold - new threshold value
 * @returns the updated threshold value
 */
const updateDeviceDataThreshold = createAsyncThunk(
  "accountSettings/updateDeviceDataThreshold",
  async (updatedDeviceDataThreshold: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${API_URL}/device-data-update-threshold?threshold=${updatedDeviceDataThreshold}`,
        null,
      );

      const result = response.data;

      if (result.IsError) {
        // Return a custom error using rejectWithValue
        throw new Error(result.ErrorMessage);
      }

      dispatch(
        showPopupMessage(
          createMessagePopup(result.ErrorCode, "Updated successfully"),
        ),
      );

      return updatedDeviceDataThreshold; // This is returned when resolved
    } catch (error: any) {
      const message: string = "Server cannot be reached.";
      dispatch(showPopupMessage(createMessagePopup(500, message)));
      return rejectWithValue(error.message);
    }
  },
);

const accountSettingsService = {
  updateDeviceDataThreshold,
};

export default accountSettingsService;
