import React, { useCallback, useRef, useState } from "react";
import {
  DropZone,
  SelectedFileNameWrapper,
  UploadWrapper,
} from "./UploadInput.style";
import { InputLabel } from "../Input.style";
import { AiOutlinePaperClip } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from "./DragUpload";
import { OpenWarningNotification } from "../../notification/Notification";
import { formatBytes } from "../../../utils";
import { CustomIconButton } from "../../button/icon-button/CustomIconButton";

export interface DragUploadProps {
  onFileUpload: (file: File) => void;
  variant?: "input";
  name?: string;
  placeHolder?: string;
  label?: string;
  width?: string;
  height?: string;
  padding?: string;
  fontSize?: string;
  maxFileSizeInBytes?: number;
  fileType?: Array<"image" | "video">;
}

const DragUpload: React.FC<DragUploadProps> = ({
  onFileUpload,
  variant,
  name,
  placeHolder,
  label,
  width,
  height,
  padding,
  fontSize,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  fileType,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const validateFileType = (file: File, fileType?: string[]) => {
    if (!fileType) return true;
    const inputFileType: string = file?.type?.split("/")[0];
    if (inputFileType && fileType.includes(inputFileType)) {
      return true;
    } else {
      const msg = fileType.join(", ");
      OpenWarningNotification(
        `Only ${msg} ${fileType.length > 1 ? "files" : "file"} allowed!`,
      );
      resetFileInput();
      return false;
    }
  };
  const handleFileUploading = (file: File) => {
    if (validateFileType(file, fileType)) {
      if (file.size <= maxFileSizeInBytes) {
        onFileUpload(file);
        setFileName(file.name);
      } else {
        OpenWarningNotification(
          `File must smaller than ${formatBytes(maxFileSizeInBytes)}`,
        );
        resetFileInput();
      }
    }
  };
  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      handleFileUploading(file);
    },
    [onFileUpload],
  );

  const resetFileInput = () => {
    if (fileInputRef?.current?.value) {
      fileInputRef.current.value = "";
    }
  };
  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
  }, []);

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        handleFileUploading(file);
      }
    },
    [onFileUpload],
  );

  const handleRemoveSelectedFile = () => {
    setFileName(null);
  };

  return (
    <UploadWrapper padding={padding} fontSize={fontSize}>
      {label ? <InputLabel htmlFor={label}>{label}</InputLabel> : ""}
      <DropZone
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        width={width}
        height={height}
      >
        <label
          htmlFor="upload-input"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            fontWeight: "normal",
          }}
        >
          {fileName ? (
            <SelectedFileNameWrapper title={fileName}>
              {fileName}
              <CustomIconButton onClick={handleRemoveSelectedFile}>
                <IoMdCloseCircle color={"#D64550"} size={"18px"} />
              </CustomIconButton>
            </SelectedFileNameWrapper>
          ) : (
            <>
              {placeHolder} <AiOutlinePaperClip />
            </>
          )}
        </label>
        <input
          ref={fileInputRef}
          id="upload-input"
          type="file"
          onChange={handleFileInputChange}
          hidden
        />
      </DropZone>
    </UploadWrapper>
  );
};

export default DragUpload;
