import React, { useEffect, useState } from "react";
import ImageCard from "../../components/image-card/ImageCard";
import permissionpage1 from "../../assets/permissionpage1.png";
import { ControlButtons } from "../../components/device-manager-card/image-card/ImageCard.style";
import { Chip } from "../../components/chip/Chip";
import { ChipStyles } from "../../enums/chip/ChipEnums";
import { SecondaryHeader } from "../../components/image-card/ImageCard.style";
import { UserCompany, UserRoles } from "../../interfaces/authentication/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { awsUtils } from "../../utils/awsUtils";

// Define props interface
interface CardProps {
  user: UserCompany;
  className?: string;
  onClick?: any;
}

// Define functional component
const UserCard: React.FC<CardProps> = ({ className, user, onClick }) => {
  const [imageUrl, setImageUrl] = useState<string>(permissionpage1); // Default image

  useEffect(() => {
    const fetchSignedImageURL = async () => {
      if (user.User?.Media?.Url) {
        const signedUrl = await awsUtils.getSignedImageURL(user.User.Media.Url);
        setImageUrl(signedUrl || permissionpage1);
      }
    };

    fetchSignedImageURL();
  }, [user.User?.Media?.Url]);
  return (
    <ImageCard
      className={`user-card ${className || ""}`}
      image={imageUrl}
      title={`${user.User.FirstName || ""} ${user.User.LastName || ""}`}
      subtitle={""}
      onClick={onClick}
    >
      <ControlButtons>
        <Chip
          children={
            UserRoles[user.RoleId] == "Owner"
              ? ChipStyles.Owner
              : UserRoles[user.RoleId] == "Editor"
              ? ChipStyles.Editor
              : ChipStyles.Viewer
          }
        />
      </ControlButtons>
      <SecondaryHeader>
        {UserRoles[user.RoleId] === "Owner"
          ? "Full Access"
          : "Restricted Access"}{" "}
        &nbsp;
        {
          <FontAwesomeIcon
            icon={
              UserRoles[user.RoleId] === "Owner" ? faCheckCircle : faCircleXmark
            }
            color={UserRoles[user.RoleId] === "Owner" ? "#80D39B" : "#D64550"}
          />
        }
      </SecondaryHeader>
    </ImageCard>
  );
};
export default UserCard;
