import styled from "styled-components";

export const CompanyOptionsCard = styled.button`
  background-color: transparent;
  width: 600px;
  display: flex;
  align-items: center;
  gap: 40px;
  border-radius: 7px;
  border: 1px solid #edae49;
  padding: 0.8vw 2vw;
  cursor: pointer;
  &:hover,
  &.selected {
    cursor: pointer;
    border: 1px solid #00916e;
  }
`;
