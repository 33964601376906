import { FormikProvider, useFormik } from "formik";
import { AccountSettingsTabWrapper } from "./AccountSettings.styled";
import { FormControl } from "../../form-field/form-control/FormControl";
import { FormLabel } from "../../form-field/form-label/FormLabel";
import Input from "../../input/Input";
import { debounce } from "lodash";
import * as Yup from "yup";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import accountSettingsService from "../../../services/account-settings/accountSettings.service";

const Data = () => {
  const dispatch = useAppDispatch();
  const { company } = useAppSelector((state) => state.persisted.auth);

  const formInitialValues = {
    updateFrequency: company.DeviceDataUpdateThreshold || "", // in minutes
  };

  const formValidationSchema = Yup.object({
    updateFrequency: Yup.number()
      .required("Update frequency is required")
      .integer("Update frequency must be an integer")
      .min(1, "Update frequency must be greater than 0"),
  });

  // Debounced form submission handler
  const handleFormSubmit = useMemo(
    () =>
      debounce(
        async (formValues: typeof formInitialValues) => {
          if (formValues.updateFrequency) {
            try {
              // Dispatch action to update device data threshold
              await dispatch(
                accountSettingsService.updateDeviceDataThreshold(
                  Number(formValues.updateFrequency),
                ),
              ).unwrap();
            } catch (error) {
              formik.resetForm(); // Reset form values if update fails.
            }
          }
        },
        1000, // debounce delay
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: handleFormSubmit,
    validateOnChange: true,
  });

  useEffect(() => {
    if (formik.isValid && formik.dirty) {
      handleFormSubmit(formik.values);
    }
    return () => handleFormSubmit.cancel(); // Cleanup debounce on unmount
  }, [formik.values, formik.isValid, handleFormSubmit, formik.dirty]);

  return (
    <AccountSettingsTabWrapper>
      <FormikProvider value={formik}>
        <FormControl>
          <FormLabel htmlFor="updateFrequency">
            Device Update Frequency
          </FormLabel>
          <Input
            name="updateFrequency"
            type="number"
            value={formik.values.updateFrequency.toString()}
            placeHolder="Enter device update frequency"
            width="100%"
            inputWrapperWidth="100%"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormControl>
      </FormikProvider>
    </AccountSettingsTabWrapper>
  );
};

export default Data;
