import styled from "styled-components";
import { CustomIconButtonProps } from "./CustomIconButton";
import { DefaultThemeColor } from "../../../app/Theme";

const getColor = (
  color: "primary" | "secondary" | "danger" | "success" | "default",
) => {
  switch (color) {
    case "primary":
      return DefaultThemeColor["primary"];
    case "secondary":
      return "#000";
    case "danger":
      return "red";
    case "success":
      return "green-100";
    case "default":
      return DefaultThemeColor["white"];
    default:
      return DefaultThemeColor["white"];
  }
};

export const CustomIconButtonStyle = styled.button<
  Pick<
    CustomIconButtonProps,
    | "fullWidth"
    | "color"
    | "variant"
    | "size"
    | "scaled"
    | "borderRadius"
    | "width"
    | "height"
  >
>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.fontSizes["md"]};
  gap: 5px;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "24px"};
  font-weight: bold;
  border: ${({ color, variant }) => {
    if (variant !== "outlined") return "none";
    return `1px solid ${getColor(color!)}`;
  }};
  background-color: transparent;
  -webkit-appearance: "none";
  appearance: "none";
  color: ${({ color, variant }) => {
    switch (color) {
      case "secondary":
        switch (variant) {
          case "filled":
            return "#000";
          default:
            return "#000";
        }
      case "danger":
        return "#000";
      case "success":
        return "#000";
      default:
        switch (variant) {
          case "filled":
            return "#000";
          default:
            return DefaultThemeColor["white"];
        }
    }
  }};

  width: ${({ fullWidth, width }) =>
    fullWidth ? "100%" : width ? width : "auto"};
  height: ${({ height }) => (height ? height : "auto")};
  padding: ${({ size }) => {
    switch (size) {
      case "xs":
        return "0.5em 0.5em ";
      case "small":
        return "0.5em 1em ";
      case "medium":
        return "0.75em 2em";
      case "large":
        return "1em 3em";
      default:
        return size;
    }
  }};

  &:hover {
    cursor: pointer;
    ${({ variant, theme }) => {
      switch (variant) {
        case "outlined":
          return `
            color: ${theme.colors["secondary"]};
            border-color:${theme.colors["secondary"]};
          `;
        default:
          return `
            font-weight: bold;`;
      }
    }};
    ${({ scaled }) => (scaled ? `transform: scale(1.1);` : "")};
  }
`;
