import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import JSZip from "jszip";
import AWS from "aws-sdk";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
  OpenWarningNotification,
} from "../../components/notification/Notification";
import { ResponseObject } from "../../interfaces/response/Response";
import { Capture, CaptureWithGroups } from "../../interfaces/capture/Capture";
import {
  validateErrorResult,
  validateFetchErrorResult,
  validateFetchSuccessResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";
import {
  MessagePopupSetup,
  showPopupMessage,
} from "../../store/slices/messagePopups/messagePopupSlice";
import { ICaptureImageCard } from "../../interfaces/cardTypes/captures";
import { awsUtils } from "../../utils/awsUtils";

import deviceImage2 from "../../assets/device2.png";
const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY!,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
  region: process.env.REACT_APP_AWS_REGION,
});

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";
interface getParamsType {
  mediaType?: string | null;
  archive?: boolean;
  ascending: boolean;
}

interface archiveParamsType {
  Id: number;
  ModelId: number;
  DeviceId: string;
  Archive: boolean;
}

interface bulkArchiveUpdateParamsType {
  CaptureIds: number[];
  ArchiveStatus: boolean;
}

interface bulkDeleteParamsType {
  CaptureIds: number[];
  ArchiveStatus: boolean;
}

interface deleteParamsType {
  Id: number;
  ModelId: number;
  DeviceId: string;
  Deleted: boolean;
}

interface searchParamsType {
  highConfidence: boolean | null;
  mediaType: string | null;
  archive: boolean;
  locationId: number;
  deviceId: string;
  modelId: number;
  ascending: boolean;
}

export const getAllCaptures = createAsyncThunk(
  "capture/getAllCaptures",
  async (params: getParamsType, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/Captures/withGroupNames`, { params })
      .then(
        async (
          response: AxiosResponse<ResponseObject<CaptureWithGroups[]>>,
        ) => {
          // Validate Success Result
          const result = response.data;
          await validateFetchSuccessResult(response);

          return setCaptures(result.Result);
        },
      )
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

export const archiveCapture = createAsyncThunk(
  "capture/patchArchive",
  async (params: archiveParamsType, { dispatch }) => {
    const response = await axios
      .patch(`${API_URL}/Captures/archive`, params)
      .then(async (response: AxiosResponse<ResponseObject<string>>) => {
        // Validate Success Result
        await validateSuccessResult(
          response,
          dispatch,
          "Capture archived successfully.",
        );

        response.data.Result = params.Id.toString();
        const result = response.data;
        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

export const softDeleteCapture = createAsyncThunk(
  "capture/softDelete",
  async (params: deleteParamsType, { dispatch }) => {
    const response = await axios
      .patch(`${API_URL}/Captures/softDelete`, params)
      .then(async (response: AxiosResponse<ResponseObject<string>>) => {
        // Validate Success Result
        await validateSuccessResult(
          response,
          dispatch,
          "Capture deleted successfully.",
        );

        response.data.Result = params.Id.toString();
        const result = response.data;
        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

export const bulkArchiveUpdate = createAsyncThunk(
  "capture/bulkArchiveUpdate",
  async (params: bulkArchiveUpdateParamsType, { dispatch }) => {
    const response = await axios
      .patch(`${API_URL}/Captures/bulkArchiveUpdate`, params)
      .then(async (response: AxiosResponse<ResponseObject<string>>) => {
        // Validate Success Result
        const result = response.data;
        await validateSuccessResult(
          response,
          dispatch,
          "Captures archived successfully.",
        );

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

export const bulkSoftDelete = createAsyncThunk(
  "capture/bulkSoftDelete",
  async (capturesIds: number[], { dispatch }) => {
    const response = await axios
      .patch(`${API_URL}/Captures/bulkDelete`, capturesIds)
      .then(async (response: AxiosResponse<ResponseObject<string>>) => {
        // Validate Success Result
        const result = response.data;
        await validateSuccessResult(
          response,
          dispatch,
          "Captures deleted successfully.",
        );

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

export const searchCaptures = createAsyncThunk(
  "capture/search",
  async (params: searchParamsType, { dispatch }) => {
    const response = await axios
      .get(`${API_URL}/Captures/withGroupNames`, { params })
      .then(
        async (
          response: AxiosResponse<ResponseObject<CaptureWithGroups[]>>,
        ) => {
          // Validate Success Result
          const result = response.data;
          await validateFetchSuccessResult(response);

          return setCaptures(result.Result);
        },
      )
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

export const handleDownloadCapture = async (
  imageUrl: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  /* 
    Not using axios utility methods here because of how 
    specific the AWS S3 Object Download method is
  */
  try {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET!,
      Key: imageUrl,
    };

    s3.getObject(params, (error, data) => {
      // Show AWS S3 error message or success message
      if (error) {
        const errorPopupMessageSetup: MessagePopupSetup = {
          code: 400,
          message: `AWS S3 Error: ${error.message}`,
        };

        dispatch(showPopupMessage(errorPopupMessageSetup));
      } else {
        if (data.Body) {
          const successPopupMessageSetup: MessagePopupSetup = {
            code: 200,
            message: "Downloaded successfully.",
          };

          const blob = new Blob([data.Body as Uint8Array]);
          // Get download URL from AWS S3 server
          const downloadUrl = URL.createObjectURL(blob);

          // Download the S3 object in the browser
          // 1.Create an anchor element to trigger the download
          const link = document.createElement("a");
          link.href = downloadUrl;
          const imageName = imageUrl.split("/").pop();
          link.download = imageName!;
          document.body.appendChild(link);
          link.click();
          // 2.Clean up resources
          document.body.removeChild(link);

          // Show success message when capture is downloaded successfully
          dispatch(showPopupMessage(successPopupMessageSetup));
        }
      }
    });
  } catch (error) {
    const unexpectedPopupMessageSetup: MessagePopupSetup = {
      code: 500,
      message: "An unexpected error occurred. Please try again later.",
    };

    console.error("Unexpected error:", error);
    dispatch(showPopupMessage(unexpectedPopupMessageSetup));
  }
};

export const bulkDownloadCaptures = async (
  imageUrls: string[],
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  /* 
    Not using axios utility methods here because of how 
    specific the AWS S3 Object Download method is
  */
  if (imageUrls.length > 0) {
    try {
      const zip = new JSZip();

      // Use Promise.all to wait for all downloads to complete
      const downloadPromises = imageUrls.map(async (imageUrl) => {
        try {
          const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET!,
            Key: imageUrl,
          };

          const data = await s3.getObject(params).promise();

          if (data.Body) {
            const imageName = imageUrl.split("/").pop();
            zip.file(imageName!, data.Body as Uint8Array, { binary: true });
          }
        } catch (error) {
          console.error(`Error downloading ${imageUrl}:`, error);
          const errorPopupMessageSetup: MessagePopupSetup = {
            code: 400,
            message: `AWS S3 Error: Image download error.`,
          };

          dispatch(showPopupMessage(errorPopupMessageSetup));
        }
      });

      const successPopupMessageSetup: MessagePopupSetup = {
        code: 200,
        message: "Downloaded successfully.",
      };

      // Handle asynchronous operations in parallel, ensuring that all image downloads complete before proceeding to create the zip file.
      await Promise.all(downloadPromises);

      // Generate the zip file
      const content = await zip.generateAsync({ type: "blob" });

      // Create a blob URL for the zip file
      const blobUrl = URL.createObjectURL(content);

      // Create an anchor element to trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = "captures.zip";
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up resources
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(downloadLink);

      // Show success message if bulk download successfully
      dispatch(showPopupMessage(successPopupMessageSetup));
    } catch (error) {
      // Show error message if something goes wrong
      const unexpectedPopupMessageSetup: MessagePopupSetup = {
        code: 500,
        message: "An unexpected error occurred. Please try again later.",
      };

      console.error("Unexpected error:", error);
      dispatch(showPopupMessage(unexpectedPopupMessageSetup));
    }
  } else {
    // Open info message if no captures selected
    const infoPopupMessageSetup: MessagePopupSetup = {
      code: null,
      message:
        "Please select the captures you would like to bulk download first.",
    };

    dispatch(showPopupMessage(infoPopupMessageSetup));
  }
};
const setCaptures = async (
  data: CaptureWithGroups[],
): Promise<ICaptureImageCard[]> => {
  const captures: ICaptureImageCard[] = [];
  for (let i = 0; i < data?.length; i++) {
    captures.push({
      id: data[i].Captures.Id,
      deviceId: data[i].Captures.DeviceId,
      modelId: data[i].Captures.ModelId,
      checked: false,
      thumbnail: await awsUtils.getSignedImageURL(
        data[i].Captures.Media.ThumbnailUrl,
      ),
      mediaPath: data[i].Captures.Media.Url,
      fullMedia: await awsUtils.getSignedImageURL(data[i].Captures.Media.Url),
      device: {
        index: i.toString(),
        dataId: data[i].Captures.DeviceId,
        image: data[i].Captures.Device.Thumbnail || deviceImage2,
        header: data[i].Captures.Device.TagName || "Unnamed Device",
        upToDate: data[i].Captures.Device.UpToDate,
        online: data[i].Captures.Device.Online,
        connectionStatus:
          data[i].Captures.Device.UpToDate && data[i].Captures.Device.Online
            ? "Connected"
            : !data[i].Captures.Device.UpToDate &&
              data[i].Captures.Device.Online
            ? "Out of Date"
            : "Disconnected",
      },
      model: data[i].Captures?.AIModel?.Name || "No Model",
      modelDeleted: data[i].Captures?.AIModel?.Deleted || false,
      modelArchived: data[i].Captures?.AIModel?.Archived || false,
      snapshortTakenTime: data[i].Captures.TakenAt,
      pairedGroup: data[i].GroupNames,
      gpuUsage: data[i].Captures.GPUUsage,
      cpuUsage: data[i].Captures.CPUUsage,
      aiConfidence: data[i].Captures.Confidence,
      temperature: data[i].Captures.Temperature,
      frame: data[i].Captures.MediaType === "image" ? "Image" : "Video",
      deleted: data[i].Captures.Deleted,
      archived: data[i].Captures.Archive,
    });
  }

  return captures;
};

const captureService = {
  getAllCaptures,
  searchCaptures,
  archiveCapture,
};

export default captureService;
