import React from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import "./App.css";
export interface ThemeProps {
  children: React.ReactNode[] | React.ReactNode;
}
export const DefaultThemeColor = {
  primary: "#EDAE49",
  secondary: "#00916E",
  white: "#ffffff",
  "violet-100": "#DA9EDD",
  "violet-200": "#D47FE9",
  "violet-300": "#9E7EA7",
  "violet-400": "#6E4C70",
  "violet-500": "#554759",
  "violet-600": "#4A364C",
  "violet-700": "#302331",
  "violet-800": "#231C23",
  "violet-900": "#0A010D",
  "grey-100": "#F4F4F4",
  "grey-200": "#F5F5F5",
  "grey-300": "#BABABA",
  "grey-400": "#9E9E9E",
  "grey-500": "#707070",
  "grey-600": "#737373",
  "grey-700": "#848484",
  "grey-800": "#444444",
  "grey-900": "#111817",
  "red-100": "#E00707",
  "red-200": "#CB350E",
  "red-300": "#D64550",
  "green-100": "#80D39B",
  "green-200": "#3EB23E",
  "green-300": "#00916E",
  "blue-100": "#0742A6",
  "blue-700": "#2A8FB4",
  "blue-900": "#4454ea",
  "blue-800": "#5F5DBB",
  "indigo-100": "#2323C6",
  "yellow-100": "#EDAE49",
  "pink-100": "#C623A5",
  "black-100": "#020A01",
  "black-200": "#111817",
  none: "none",
};
export const DefaultThemeBorder = {
  none: "none",
  "border-xl-violet": "7px solid #9E7EA7",
  "border-sm-white": "1px solid #707070",
  "border-sm-gray": "1px solid #9E9E9E",
  "border-sm-dark-gray": "1px solid #707070",
  "border-sm-green": "1px solid #00916E",
  "border-sm-yellow": "1px solid #EDAE49",
  "border-md-yellow": "2px solid #EDAE49",
  "border-xl-yellow": "4px solid #EDAE49",
  "primary-sm": `1px solid ${DefaultThemeColor.primary}`,
  "primary-md": `2px solid ${DefaultThemeColor.primary}`,
  "primary-xl": `4px solid ${DefaultThemeColor.primary}`,
  "secondary-sm": `1px solid ${DefaultThemeColor.secondary}`,
  "secondary-md": `1px solid ${DefaultThemeColor.secondary}`,
};
export const DefaultThemeFontSize = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  "2xl": "1.5rem",
  "3xl": "1.875rem",
  "4xl": "2.25rem",
  "5xl": "3rem",
  "6xl": "3.75rem",
  "7xl": "4.5rem",
  "8xl": "6rem",
  "9xl": "8rem",
};
export const DefaultThemeBreakpoints = {
  sm: "48em",
  md: "64em",
  lg: "85.375em",
  xl: "120em",
  xxl: "160em",
  xxxl: "180em",
};
export const defaultTheme: DefaultTheme = {
  colors: DefaultThemeColor,
  // font size for em(responsiveness)
  fontSizes: DefaultThemeFontSize,
  borderRadius: {
    "rounded-sm": "2px",
    "rounded-md": "4px",
    "rounded-lg": "6px",
    "rounded-xl": "8px",
    "rounded-2xl": "12px",
    "rounded-3xl": "16px",
    "rounded-4xl": "24px",
    "rounded-5xl": "32px",
    "rounded-full": "9999px",
  },
  boxShadow: {},
  spacing: {
    xxs: "4px",
    xs: "8px",
    sm: "12px",
    md: "16px",
    lg: "20px",
    xl: "24px",
    xxl: "32px",
  },
  border: DefaultThemeBorder,
  fontWeight: {
    normal: "400",
    light: "300",
    medium: "500",
    semiBold: "600",
    bold: "700",
    extraBold: "800",
    black: "900",
  },
  breakpoints: DefaultThemeBreakpoints,
  mediaQuery: {
    sm: `(min-width: ${DefaultThemeBreakpoints.sm})`,
    md: `(min-width: ${DefaultThemeBreakpoints.md})`,
    lg: `(min-width: ${DefaultThemeBreakpoints.lg})`,
    xl: `(min-width: ${DefaultThemeBreakpoints.xl})`,
    xxl: `(min-width: ${DefaultThemeBreakpoints.xxl})`,
    retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)",
  },
};

export const Theme: React.FC<ThemeProps> = ({ children }) => {
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
};
