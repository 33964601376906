import styled from "styled-components";

export const CustomImageCard = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors["grey-900"]};
  &:hover {
    background-color: ${(props) => props.theme.colors["primary"]};
    color: #000;
    * {
      color: #000;
    }
  }
  color: #fff;
  padding: 8px;
  max-width: 100%;
  width: 16.6%;
  min-width: 16.6%;
  height: max-content;
  cursor: pointer;
`;

export const NavigationButtonContainer = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: space-between;
`;

export const NavigationButton = styled.button`
  background-color: #fff;
  border: none;
  padding: 8px;
`;

export const CardContent = styled.div`
  padding: 8px;
  height: 40%;
`;

export const Header = styled.p`
  color: ${(props) => props.theme.colors["primary"]};
  font-size: ${(props) => props.theme.fontSizes["sm"]};
`;

export const SecondaryHeader = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
`;
