import styled from "styled-components";

export const AddDeviceModelImageBox = styled.button`
  background-color: transparent;
  width: calc(22% - 1rem);
  padding: 1rem;
  height: 200px;
  justify-content: center;
  border-radius: 5px;
  border: none;
`;
