import {
  AccordionContainer,
  AccordionContent,
  AccordionDescription,
  AccordionHeader,
  AccordionList,
  AccordionToggle,
  HorizontalLine,
  VerticalLine,
  VerticalLineWrapper,
} from "./Accordion.style";
import { MdKeyboardArrowDown } from "react-icons/md";

export interface IAccordionProps {
  id: number;
  title: string;
  description: JSX.Element | string;
  alignItems: string;
  margin: string;
  label: string;
  isActive: boolean;
  variant?: "xs" | "small" | "large";
  color: string;
  width: string;
  justifyContent?: string;
  display: string;
  verticalLine?: boolean;
  backgroundColor?: string;
  borderTheme?: string;
  iconColor?: string;
  defaultValue?: number;
  handleToggle: () => void;
}
//Not in active use with containers
export const Accordion: React.FC<IAccordionProps> = ({
  title,
  description,
  backgroundColor,
  borderTheme,
  isActive,
  handleToggle,
}) => {
  return (
    <AccordionContainer>
      <AccordionList
        backgroundColor={backgroundColor}
        borderTheme={borderTheme}
        onClick={handleToggle}
      >
        <AccordionHeader>
          {title}
          <VerticalLineWrapper>
            <VerticalLine />
            <AccordionToggle isExpanded={isActive}>
              <MdKeyboardArrowDown />
            </AccordionToggle>
          </VerticalLineWrapper>
        </AccordionHeader>
        <AccordionContent className={isActive ? "expanded" : ""}>
          <HorizontalLine isExpanded={isActive} />
          <AccordionDescription>{description}</AccordionDescription>
        </AccordionContent>
      </AccordionList>
    </AccordionContainer>
  );
};
