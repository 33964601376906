import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleXmark,
  faExclamationCircle,
  faLayerGroup,
  faWifi,
  faSignal5,
  faGear,
  faMicrochip,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ChipStyles } from "../../../../enums/chip/ChipEnums";
import { NavigationButton } from "../../../../components/button/navigation-button/NavigationButton";
import { Chip } from "../../../../components/chip/Chip";
import {
  ControlButtons,
  DeviceDate,
} from "../../../../components/device-manager-card/image-card/ImageCard.style";
import { FlexContainer } from "../../../../components/flex-container/FlexContainer";
import { Typography } from "../../../../components/typrography/Typography";
import { CustomInfoCard } from "../../../../components/info-card/InfoCard.style";
import EditDeviceName from "./DeviceName";
import DeviceLocation from "./DeviceLocation";
import { DefaultThemeColor } from "../../../../app/Theme";
import { SwrmDevices } from "../../../../interfaces/device/SwrmDevice";
import { getDeviceStatus, getDeviceWifiStatus } from "../../DeviceCard";
import deviceImage2 from "../../../../assets/device2.png";
import DeviceArchive from "./DeviceArchive";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { StyledFontAwesomeIconButton } from "../../../../components/device-manager-card/card-header/CardHeader.style";
import DeviceWifiSettingsModal from "./DeviceWifiSettingsModal";
import { Tooltip } from "react-tooltip";
import deviceConfigService from "../../../../services/device/deviceConfig.service";
import { useAppDispatch } from "../../../../store/hooks";
import { CustomIconButton } from "../../../../components/button/icon-button/CustomIconButton";

interface Props {
  device: SwrmDevices;
  next: any;
  prev: any;
}

export const InfoCardDeviceManager: React.FC<Props> = ({
  device,
  next,
  prev,
}) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleUpdateFirmware = async () => {
    setIsLoading(true);
    await deviceConfigService.sendFirmwareUpdate(device.Id, dispatch);
    setIsLoading(false);
  };

  const renderDeviceStatus = () => {
    let status = getDeviceStatus(device.UpToDate, device.ConnectionStatus);
    let wifiStatus = getDeviceWifiStatus(device.WifiStatus);
    return (
      <>
        {/* Status Connection */}
        <FlexContainer justifyContent="flex-start" gap="0.5rem">
          <Typography variant="lg">
            <FontAwesomeIcon icon={faSignal5} />
          </Typography>
          <Typography variant="xl" weight="light">
            {status}
          </Typography>
          <Typography variant="xl">
            <FontAwesomeIcon
              icon={
                status === "Online"
                  ? faCheckCircle
                  : status === "Out of Date"
                  ? faExclamationCircle
                  : faCircleXmark
              }
              color={
                status === "Online"
                  ? "#80D39B"
                  : status === "Out of Date"
                  ? DefaultThemeColor["primary"]
                  : "#D64550"
              }
            />
          </Typography>
        </FlexContainer>

        {/* Wi-Fi Connection */}
        <FlexContainer justifyContent="flex-start" gap="0.5rem">
          <Typography variant="lg">
            <FontAwesomeIcon icon={faWifi} />
          </Typography>
          <Typography variant="xl" weight="light">
            Wi-Fi Settings
          </Typography>
          {/* <Typography variant="xl">
            <FontAwesomeIcon
              icon={device.WifiStatus ? faCheckCircle : faCircleXmark}
              color={device.WifiStatus ? "#80D39B" : "#D64550"}
            />
          </Typography> */}
          <CustomIconButton onClick={openModal}>
            <StyledFontAwesomeIconButton
              icon={faGear}
              cursor="pointer"
              data-tooltip-id="wifi-settings-tooltip"
              data-tooltip-content="Update Wi-Fi Connection"
              data-tooltip-place="top"
            />
          </CustomIconButton>
          <Tooltip id="wifi-settings-tooltip" />
        </FlexContainer>

        {/* Firmware Update */}
        <FlexContainer justifyContent="flex-start" gap="0.5rem">
          <Typography variant="xl">
            <FontAwesomeIcon icon={faMicrochip} />
          </Typography>
          <Typography variant="xl" weight="light">
            Firmware {device.UpToDate ? "up to date" : "out of date"}
          </Typography>
          <Typography variant="xl">
            <FontAwesomeIcon
              icon={device.UpToDate ? faCheckCircle : faCircleXmark}
              color={device.UpToDate ? "#80D39B" : "#D64550"}
            />
          </Typography>
          <CustomIconButton onClick={handleUpdateFirmware}>
            <StyledFontAwesomeIconButton
              icon={faArrowsRotate}
              cursor="pointer"
              data-tooltip-id="firmware-tooltip"
              data-tooltip-content="Update Firmware"
              data-tooltip-place="top"
            />
          </CustomIconButton>
          <Tooltip id="firmware-tooltip" />
        </FlexContainer>
      </>
    );
  };
  return (
    <CustomInfoCard>
      {/* image */}
      <LazyLoadImage
        key={device.Thumbnail}
        src={device.Thumbnail || deviceImage2}
        alt={device.Name}
        effect="blur"
        width="100%"
        height="100%"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />

      {/* content */}
      <FlexContainer
        justifyContent="space-between"
        alignItems="flex-start"
        height="100%"
      >
        <FlexContainer
          flexDirection="column"
          justifyContent="stretch"
          alignItems="flex-start"
          gap="32px"
        >
          <FlexContainer
            justifyContent="flex-start"
            gap="12px"
            alignItems="flex-start"
          >
            {/* chip */}
            <ControlButtons>
              <Chip
                children={
                  device.Deployed ? ChipStyles.Deployed : ChipStyles.Undeployed
                }
                backgroundColor={device.Deployed ? "blue" : "grey"}
              />
            </ControlButtons>

            {/* the device serial number */}
            {device.PiSerial && (
              <div>
                <Typography variant="sm" weight="light">
                  Console Serial No. : {device.PiSerial}
                </Typography>
                {device.ChildDevices?.map((d) => (
                  <Typography variant="sm" weight="light" key={d.Id}>
                    Sensor Board Serial No. : {d.PiSerial}
                  </Typography>
                ))}
              </div>
            )}
          </FlexContainer>

          {/* header and additional content */}
          <FlexContainer
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            {/* primary and secondary header */}
            <FlexContainer
              flexDirection="column"
              alignItems="flex-start"
              gap="1rem"
            >
              <FlexContainer justifyContent="flex-start">
                <Typography variant="4xl" weight="bold">
                  {device.Name}&nbsp;
                </Typography>
                <EditDeviceName
                  deviceName={device.Name}
                  deviceId={device.Id || ""}
                />
                <DeviceArchive
                  deviceName={device.Name}
                  deviceId={device.Id || ""}
                />
              </FlexContainer>

              {/* other info */}
              {renderDeviceStatus()}
            </FlexContainer>

            {/* MAke additional data container from this  */}

            <div
              style={{
                paddingTop: "0.5rem",
                gap: "0.25rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {/* location */}
              <DeviceLocation
                deviceId={device?.Id || ""}
                location={device?.Location}
                deployed={device.Deployed || false}
              />
              {/* GPS Status */}
              <FlexContainer gap="0.5rem">
                <Typography variant="lg">
                  <FontAwesomeIcon icon={faLayerGroup} />
                </Typography>
                <Typography variant="xl">
                  GPS Status
                  {":"}
                </Typography>
                <Typography variant="xl" color={"red-300"}>
                  Not Tracking
                </Typography>
              </FlexContainer>
            </div>
          </FlexContainer>
        </FlexContainer>
        {/* ------------------------------------------------------------------------- */}
        <FlexContainer
          justifyContent="space-between"
          flexDirection="column"
          alignItems="flex-end"
          height="100%"
        >
          <FlexContainer gap="4px">
            <NavigationButton onClick={prev}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </NavigationButton>
            <NavigationButton onClick={next}>
              <FontAwesomeIcon icon={faChevronRight} />
            </NavigationButton>
          </FlexContainer>

          {/* 
            November 12, 2024
            Commented out code for the time being as per 
            Mocreeebec request. Will fix and re-implement in
            the future.
          */}
          {/* <FlexContainer flexDirection="column" gap="16px">
            <DeviceDate>
              <Typography variant="sm" weight="bold" direction="flex-end">
                Last Connected:
              </Typography>
              <Typography variant="sm" weight="light" direction="flex-end">
                {time12hDate(device.LastContact)}
              </Typography>
            </DeviceDate>
          </FlexContainer> */}
        </FlexContainer>
      </FlexContainer>
      <DeviceWifiSettingsModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        device={device}
      />
    </CustomInfoCard>
  );
};
