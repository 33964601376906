import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import styled from "styled-components";

interface SortbyIconProps {
  isActive: boolean;
}

export const SortbyIcon = styled.button<SortbyIconProps>`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  border: ${(props) =>
    props.isActive
      ? props.theme.border["primary-sm"]
      : props.theme.border["border-sm-gray"]};
  border-radius: 3px;
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    border: ${(props) =>
      props.isActive
        ? props.theme.border["primary-sm"]
        : props.theme.border["border-sm-green"]};
    & > svg {
      color: ${(props) => props.theme.colors["secondary"]};
    }
  }
`;

export const IconSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StyledFaSortAmountDownAlt = styled(FaSortAmountDownAlt)`
  color: #707070;
`;

export const StyledFaSortAmountUp = styled(FaSortAmountUp)`
  color: #707070;
`;
