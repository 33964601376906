import React, { useState } from "react";
import { FilterButtonWrapper, StyledModal } from "./Modals.style";
import { IoClose } from "react-icons/io5";
import { Typography } from "../typrography/Typography";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";

interface ModalProps {
  buttonLabel: string;
  listTitle?: string;
  onClose?: any;
  children?: React.ReactNode[] | React.ReactNode;
}

const FancyModalButton = ({
  buttonLabel,
  listTitle,
  onClose,
  children,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const toggleModal = () => {
    setIsHovered(false);
    setOpacity(0);
    setIsOpen(!isOpen);
  };

  const afterOpen = () => {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  };

  const beforeClose = () => {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 100);
      onClose && onClose();
    });
  };

  return (
    <>
      <FilterButtonWrapper>
        <Typography weight="bold" style={{ fontSize: "20px" }}>
          {listTitle}
        </Typography>
        <button onClick={toggleModal}>{buttonLabel}</button>
      </FilterButtonWrapper>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        backgroundProps={{ opacity }}
      >
        <CustomIconButton onClick={toggleModal}>
          <IoClose
            style={{
              position: "absolute",
              right: "0.5rem",
              top: "0.5rem",
              cursor: "pointer",
            }}
            size="2rem"
            color={isHovered ? "#FFF" : "#707070"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </CustomIconButton>
        {children ? children : null}
      </StyledModal>
    </>
  );
};

export default FancyModalButton;
