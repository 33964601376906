import React from "react";
import { StyledIoMdTrash } from "../tables/Table.style";
import { Tooltip } from "react-tooltip";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";

interface DeleteIconProps {
  handleDeleteOnClick: () => void;
  size: string;
  tooltip?: string;
  disabled?: boolean;
}

const DeleteIcon: React.FC<DeleteIconProps> = ({
  handleDeleteOnClick,
  size,
  tooltip,
  disabled,
}) => {
  return (
    <CustomIconButton onClick={handleDeleteOnClick}>
      <StyledIoMdTrash
        data-tooltip-id="delete-tooltip"
        data-tooltip-content={tooltip || "Delete"}
        data-tooltip-place="top"
        size={size}
        className={disabled ? "disabled" : ""}
      />
      <Tooltip id="delete-tooltip" />
    </CustomIconButton>
  );
};

export default DeleteIcon;
