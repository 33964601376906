import styled from "styled-components";
import { DropdownProps } from "./CustomDropdown";
interface DropDownBtnProps {
  isActive: boolean;
}

export const VerticalLineWrapper = styled.div`
  height: 100%;
  display: flex;
`;

interface DropdownMenuContainerProps {
  padding: string;
}
export const IconButtonWrapper = styled.div<DropDownBtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
  transform: ${(props) => (props.isActive ? `rotate(180deg)` : "")};
`;

export const DropdownContainer = styled.div<
  Pick<DropdownProps, "alignItems" | "width">
>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  flex-direction: column;
  cursor: pointer;
  width: ${({ width }) => (width ? width : "auto")};
`;

export const DropdownMenu = styled.button<
  Pick<
    DropdownProps,
    "variant" | "color" | "width" | "backgroundColor" | "borderTheme" | "margin"
  >
>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ variant, width }) =>
    width
      ? width
      : variant === "small"
      ? "9rem"
      : variant === "large"
      ? "18rem"
      : "auto"};
  min-width: 6.75rem;
  height: auto;
  // min-height: 40px;
  min-height: 2.25rem;
  border: ${({ borderTheme, theme }) =>
    borderTheme ? borderTheme : theme.border["primary-sm"]};
  border-radius: 5px;
  font-size: ${(props) => props.theme.fontSizes["sm"]};
  margin: ${({ margin, variant }) =>
    margin
      ? margin
      : variant === "small" || variant === "large"
      ? "4px 0"
      : variant === "xs"
      ? "0 1rem 0 0"
      : "1rem 1rem 1rem 0"};
  padding: 7px;
  background: ${({ variant, backgroundColor }) =>
    backgroundColor ? backgroundColor : variant === "large" ? "#020A01" : ""};

  &:hover {
    border: ${(props) => props.theme.border["secondary-sm"]};
  }
`;

export const VerticalLine = styled.div<Pick<DropdownProps, "borderTheme">>`
  width: 20px;
  height: 100%;
  border-right: ${({ borderTheme, theme }) =>
    borderTheme ? borderTheme : theme.border["primary-sm"]};
`;
export const DropdownMenuContainer = styled.div<DropdownMenuContainerProps>`
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : 0)};
  flex-wrap: wrap;
`;

interface OptionProps {
  variant?: string;
  width?: string;
}
export const OptionsContainer = styled.div<OptionProps>`
  position: absolute;
  overflow-y: auto !important;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: block !important;
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 1px;
  }
  top: ${({ variant }) =>
    variant === "small" || variant === "large"
      ? "45px"
      : variant === "xxs"
      ? "25px"
      : "56px"};
  left: 0;
  display: flex;
  justify-content: start;
  align-items: start;
  text-align: left;
  flex-direction: column;
  background: ${(props) => props.theme.colors["black-100"]};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  /* width: ${({ variant, width }) =>
    width ? width : variant === "large" ? "400px" : "180px"}; */
  width: ${({ width }) => (width ? width : "auto")};
  min-width: -webkit-fill-available;
  // min-height: 40px;
  min-height: 2.25rem;
  max-height: 125px;
  font-size: ${(props) => props.theme.fontSizes["xs"]};
  @media (max-width: 1536px) {
    font-size: ${(props) => props.theme.fontSizes["sm"]};
  }
  z-index: 10;

  & > * {
    list-style-type: none;
  }
  & > ul {
    width: 100%;
  }
  & > ul > li {
    padding: 5px;
  }
`;
