import styled from "styled-components";
import { SubscriptionCardProps } from "./SubscriptionCard";

export const CardWrapper = styled.button<
  Pick<SubscriptionCardProps, "displayTriangleTag">
>`
  background-color: transparent;
  text-align: start;
  display: flex;
  flex-direction: column;
  width: 15vw;
  border-radius: 7px;
  border: ${(props) => props.theme.border["primary-sm"]};
  padding: 0.8vw;
  position: relative;

  &:hover,
  &.selected {
    cursor: pointer;
    border: ${(props) => props.theme.border["secondary-sm"]};
  }

  ${({ displayTriangleTag }) =>
    displayTriangleTag
      ? `
        &:hover:first-child{
            background:#80D39B;
        }
    `
      : ""}
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TriangleTagWrapper = styled.div<
  Pick<SubscriptionCardProps, "displayTriangleTag">
>`
  display: ${({ displayTriangleTag }) =>
    displayTriangleTag ? displayTriangleTag : "none"};
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 2.5vw;
  border-color: ${(props) => props.theme.colors["primary"]}
    ${(props) => props.theme.colors["primary"]} transparent transparent;
  float: left;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 7px 0px 0px;

  ${CardWrapper}:hover & {
    border-color: #80d39b #80d39b transparent transparent;
  }
`;

export const SubscriptionWrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const CurrentSubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: ${(props) => props.theme.border["primary-sm"]};
  padding-right: 2vw;
`;
export const AllSubscriptionWrapper = styled.div`
  display: flex;
  padding: 0 2vw;
  flex-direction: column;
`;
export const AllSubscriptionCardWrapper = styled.div`
  display: flex;
  gap: 2vw;
`;
export const TextButtonWrapper = styled.div`
  display: flex;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
