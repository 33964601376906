import styled from "styled-components";

export const ImageCardWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 1rem;
  position: relative;
  background-color: #020a01;
  width: 100%;
  flex-wrap: wrap;
`;

export const CustomImageCard = styled.button`
  text-align: start;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border-style: none;
  background-color: ${(props) => props.theme.colors["grey-900"]};
  &.selected,
  &:hover {
    background-color: ${(props) => props.theme.colors["primary"]};
    color: #000;
    * {
      color: #000;
    }
  }
  cursor: pointer;
  color: #fff;
  padding: 0.725rem;
  max-width: 100%;
  width: 30%;
  min-width: 30%;
  height: 13rem;
  min-height: max-content;
  @media ${(props) => props.theme.mediaQuery["sm"]} {
    width: 30%;
    min-width: 30%;
  }
  @media ${(props) => props.theme.mediaQuery["md"]} {
    width: 23%;
    min-width: 23%;
  }

  @media ${(props) => props.theme.mediaQuery["lg"]} {
    width: 18%;
    min-width: 18%;
  }

  @media ${(props) => props.theme.mediaQuery["xl"]} {
    width: 18%;
    min-width: 18%;
  }

  @media ${(props) => props.theme.mediaQuery["xxl"]} {
    width: 15%;
    min-width: 15%;
  }
  @media ${(props) => props.theme.mediaQuery["xxl"]} {
    width: 15%;
    min-width: 15%;
  }
  &.device-card {
    height: 14rem;
  }
`;

export const NavigationButtonContainer = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: space-between;
`;

export const NavigationButton = styled.button`
  background-color: #fff;
  border: none;
  padding: 8px;
`;

export const CardContent = styled.div`
  height: 40%;
  padding-top: 0.25rem;
  & > div,
  & > p {
    padding: 0.125rem 0;
  }
  width: 100%;
`;

export const Header = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: ${(props) => props.theme.colors["primary"]};
  font-size: ${(props) => props.theme.fontSizes["sm"]};
  &:hover {
    color: #000;
  }
`;

export const SecondaryHeader = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(props) => props.theme.fontSizes.sm};
`;
