import { G2, Gauge } from "@ant-design/charts";
import { Typography } from "../typrography/Typography";
import { GaugeChartWrapper } from "./DataStats.style";
import {
  sensorNameToReadableName,
  sensorValueWithUnit,
} from "../../utils/dashboardUtils";

interface DaugePanelProps {
  percent: number;
  type: string;
  title: string;
  variant: string;
  titleVariant?: "sm" | "md" | "lg" | "xl";
  indicator?: boolean;
}
// Define initial ranges for different variants
const initialRanges: any = {
  Temperature: { min: -50, max: 50 },
  Wind: { min: 0, max: 120 },
  Humidity: { min: 0, max: 100 },
  Current: { min: -100, max: 100 },
  AQI: { min: 1, max: 6 },
  Door: { min: 0, max: 0 },
  // rest will be 0 to 100 and adjust accordingly to value
};

const GaugePanel = ({
  percent = 0,
  type,
  title,
  variant,
  titleVariant = "xl",
  indicator = true,
}: DaugePanelProps) => {
  const { registerShape, Util } = G2; // Customize the shape
  const Normalizer = (min: number, max: number) => ({
    normalize: (x: number) => Math.round(min + x * (max - min)),
    deNormalize: (x: number) => (x - min) / (max - min),
  });
  // Calculate min and max based on initial or dynamic range
  const calculateMinMax = (value: number, variant: string) => {
    const { min: initialMin, max: initialMax } = Object.keys(
      initialRanges,
    ).includes(variant)
      ? initialRanges[variant]
      : { min: 0, max: 100 };

    let min = initialMin;
    let max = initialMax;

    if (value < initialMin) {
      min = Math.floor((value - 10) / 10) * 10; // Adjust the min value dynamically
      max = initialMax;
    } else if (value > initialMax) {
      max = Math.ceil((value + 10) / 10) * 10; // Adjust the max value dynamically
      min = initialMin;
    }

    return { min, max };
  };

  const { min, max } = calculateMinMax(percent, variant);

  // Normalizer function using dynamically calculated min and max
  const gaugeNormalizer = Normalizer(min, max);

  registerShape("point", "triangle-gauge-indicator", {
    draw(cfg: any, container: any) {
      // use customInfo to pass the params
      const { indicator, defaultColor } = cfg.customInfo;
      const { pointer } = indicator;
      const group = container.addGroup(); // Get the center point of the canvas in polar coordinate system

      const center = this.parsePoint({
        x: 0,
        y: 0,
      }); // Draw Pointer

      if (pointer) {
        const { startAngle, endAngle } = Util.getAngle(cfg, this.coordinate);
        const radius = this.coordinate.getRadius();
        const midAngle = (startAngle + endAngle) / 2;
        const { x: x1, y: y1 } = Util.polarToCartesian(
          center.x,
          center.y,
          radius * 0.52,
          midAngle + Math.PI / 30,
        );
        const { x: x2, y: y2 } = Util.polarToCartesian(
          center.x,
          center.y,
          radius * 0.52,
          midAngle - Math.PI / 30,
        );
        const { x, y } = Util.polarToCartesian(
          center.x,
          center.y,
          radius * 0.6,
          midAngle,
        );
        const path = [["M", x1, y1], ["L", x, y], ["L", x2, y2], ["Z"]]; // pointer

        group.addShape("path", {
          name: "pointer",
          attrs: {
            path,
            fill: defaultColor,
            ...pointer.style,
          },
        });
      }

      return group;
    },
  } as any); // Add type assertion here to fix the TypeScript error "Property 'parsePoint' does not exist on type 'RegisterShape'.ts

  const config = {
    percent: gaugeNormalizer.deNormalize(percent),
    range: {
      ticks: [0, 1],
      color: ["l(0) 0:#5596F8 1:#F18344"],
      // color: ['l(0) 0:#F4664A 0.5:#FAAD14 1:#30BF78'],
    },
    indicator: !indicator
      ? undefined
      : {
          shape: "triangle-gauge-indicator",
          pointer: {
            style: {
              fill: "#30BF78",
            },
          },
        },
    axis: {
      label: {
        formatter(value: any) {
          return `${gaugeNormalizer.normalize(value)}`;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    style: {
      width: "8em",
      height: "8em",
    },
    statistic: {
      title: {
        formatter: () => {
          return sensorValueWithUnit(variant, percent);
        },
        style: ({ percent }: any) => {
          return {
            lineHeight: 1,
            color: "white",
          };
        },
      },
      content: {
        style: {
          fontSize: "14px",
          color: "white",
          lineHeight: "38px",
        },
        formatter: () => {
          // if (variant === "Temperature") {
          //   return `Warehouse 1`;
          // }

          return "";
        },
      },
    },
  };

  return (
    <GaugeChartWrapper>
      <Typography variant="xl" display="flex" direction="flex-start">
        {sensorNameToReadableName(type)}
      </Typography>
      <Typography
        variant={titleVariant}
        color="primary"
        display="flex"
        direction="flex-start"
      >
        {title}
      </Typography>
      <Gauge {...config} />
    </GaugeChartWrapper>
  );
};

export default GaugePanel;
