import { Buffer } from "buffer";
import AWS from "aws-sdk";
import {
  KmsKeyringBrowser,
  KMS,
  getClient,
  buildClient,
  CommitmentPolicy,
  buildDecrypt,
} from "@aws-crypto/client-browser";
import { decodeString } from "./encodingUtils";
import axios, { AxiosResponse } from "axios";
import { ResponseObject } from "../interfaces/response/Response";
window.Buffer = window.Buffer || require("buffer").Buffer;

// Utility Interfaces
interface AWSParams {
  Bucket: string;
  Key: string;
  Expires: number;
}
interface AWSCredentials {
  Bucket: string;
  AccessKeyId: string;
  SecretAccessKey: string;
  S3Region: string;
  Kms: string;
}
// Utility Variables
const s3Bucket = process.env.REACT_APP_AWS_BUCKET!;
const verificationCode = process.env.REACT_APP_VC!;
const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";
// AWS Clients and dependencies
const { decrypt } = buildDecrypt();
let s3Client: AWS.S3 | null = null;
let kmsClient: any | null = null;

const fetchAWSCredentials = async () => {
  try {
    const response: AxiosResponse<ResponseObject<AWSCredentials>> =
      await axios.put(`${API_URL}/Authentication/aws`, {
        VerificationCode: verificationCode,
      }); // Replace with your API endpoint
    console.log(JSON.stringify(response.data));
    const { AccessKeyId, SecretAccessKey, S3Region } = response.data.Result;

    // Update AWS Config
    AWS.config.update({
      accessKeyId: AccessKeyId,
      secretAccessKey: SecretAccessKey,
      region: S3Region,
    });

    // Initialize clients
    s3Client = new AWS.S3();
    kmsClient = getClient(KMS, {
      credentials: {
        accessKeyId: AccessKeyId,
        secretAccessKey: SecretAccessKey,
      },
    });

    console.log("AWS SDK configured successfully with dynamic credentials");
  } catch (error) {
    console.error("Failed to fetch AWS credentials", error);
    throw new Error("AWS credential configuration failed");
  }
};
const fetchKMSKey = async () => {
  try {
    const response: AxiosResponse<ResponseObject<string>> = await axios.put(
      `${API_URL}/Authentication/kms`,
      {
        VerificationCode: verificationCode,
      },
    ); // Replace with your API endpoint
    // Update AWS Config
    console.log(JSON.stringify(response.data.Result));
    return response.data.Result;
    console.log("AWS SDK configured successfully with dynamic credentials");
  } catch (error) {
    console.error("Failed to fetch AWS credentials", error);
    throw new Error("AWS credential configuration failed");
  }
};
// Utility Methods
const getSignedImageURL = async (imageURL: string) => {
  if (!imageURL) return null;
  if (imageURL.includes("http://") || imageURL.includes("https://")) {
    return imageURL;
  }
  const params: AWSParams = {
    Bucket: s3Bucket,
    Key: imageURL,
    Expires: 3600,
  };
  // Ensure AWS clients are configured
  if (!s3Client) {
    await fetchAWSCredentials();
  }
  const signedUrl = s3Client!.getSignedUrl("getObject", params);
  return signedUrl;
};

export const awsUtils = {
  getSignedImageURL,
};

export const decryptData = async (data: string) => {
  // Ensure AWS clients are configured
  if (!kmsClient) {
    console.log(kmsClient);
    console.log("kms is null");
    await fetchAWSCredentials();
  }
  try {
    const kmsarn = await fetchKMSKey();
    console.log("kms arn: ", kmsarn);
    const keyIds = [kmsarn];
    const keyring = new KmsKeyringBrowser({
      clientProvider: kmsClient!,
      keyIds,
    });

    const { plaintext } = await decrypt(keyring, Buffer.from(data, "base64"));

    const tokenByteArray = Array.from(plaintext);
    const tokenString = btoa(String.fromCharCode.apply(null, tokenByteArray));

    return tokenString;
  } catch (error: any) {
    console.log(error.messageHeader);
    console.log(error.stack);
  }
};
