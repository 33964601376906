import styled from "styled-components";
import { InputWithButtonProps } from "./InputWithButton";

export const InputWrapper = styled.div<
  Pick<InputWithButtonProps, "width" | "padding" | "fontSize">
>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: ${({ fontSize }) =>
    fontSize ? fontSize : ({ theme }) => theme.fontSizes.lg};
  font-weight: bold;
  flex-direction: column;
  position: relative;
  padding: ${({ padding }) => (padding ? padding : "0.5rem 0")};
  ${({ width }) => (width ? `width: ${width}` : "")}
`;

export const InputLabel = styled.label<{ isColoredLabel?: boolean }>`
  display: flex;
  color: ${({ theme, isColoredLabel }) =>
    isColoredLabel ? theme.colors.primary : "white"};
  margin-bottom: 0.5rem;
`;

export const CustomInput = styled.input.attrs<InputWithButtonProps>(
  ({ value, type }) => ({
    value: value,
    type: type ? type : "text",
  }),
)<
  Pick<
    InputWithButtonProps,
    | "width"
    | "height"
    | "fontSize"
    | "margin"
    | "alignCenter"
    | "padding"
    | "disabled"
  >
>`
  border: ${({ theme, disabled }) =>
    disabled
      ? theme.border["border-sm-dark-gray"]
      : theme.border["primary-sm"]};
  background: #020a01;
  width: ${({ width }) => width || "200px"};
  height: ${({ height }) => height || "2.25rem"};
  color: white;
  padding: ${({ padding }) => (padding ? padding : "0.2rem")};
  margin: ${({ margin }) => (margin ? margin : "0 0")};
  border-radius: 5px;
  text-align: ${({ alignCenter }) => alignCenter && "center"};
  font-size: ${({ fontSize }) =>
    fontSize ? fontSize : ({ theme }) => theme.fontSizes.sm};
  cursor: ${({ disabled }) => disabled && "not-allowed"};

  &:hover {
    border: ${(props) => props.theme.border["secondary-sm"]};
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors["secondary"]};
    ::-webkit-input-placeholder {
      color: transparent;
    }
  }

  &::placeholder {
    color: #b5b5b5;
    font-size: ${(props) => props.theme.fontSizes["sm"]};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const ButtonPositioner = styled.div`
  position: absolute;
  right: 0;
  z-index: 50;
`;

export const InputButtonWrapper = styled.div`
  display: "flex";
  align-content: "center";
  flex-wrap: "wrap";
  width: "100%";
`;

export const SubLabel = styled.span`
  display: flex;
  color: #707070;
  font-size: ${(props) => props.theme.fontSizes["xs"]};
  font-weight: normal;
  align-items: center;
`;

export const ErrorMsg = styled.div`
  color: #d64550;
  font-size: ${(props) => props.theme.fontSizes["md"]};
  font-weight: 400;
`;

export const ErrorMsgWrapper = styled.div<
  Pick<InputWithButtonProps, "fontSize">
>`
  height: ${({ fontSize }) => (fontSize ? fontSize : "1rem")};
  margin-top: "10px";
`;

export const InputErrorMsg = styled.span`
  height: 1rem;
  color: #d64550;
  font-size: ${(props) => props.theme.fontSizes["md"]};
  font-weight: 400;
`;
