import styled from "styled-components";

export const NavBarTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  width: 80%;
  height: 100%;
`;

export const TitleWrapper = styled.div`
  font-size: clamp(
    ${(props) => props.theme.fontSizes["xl"]},
    ${(props) => props.theme.fontSizes["4xl"]},
    ${(props) => props.theme.fontSizes["6xl"]}
  );
`;

export const SubTitleWrapper = styled.div`
  font-size: clamp(
    ${(props) => props.theme.fontSizes["md"]},
    ${(props) => props.theme.fontSizes["lg"]},
    ${(props) => props.theme.fontSizes["xl"]}
  );
`;
