import React, { useRef } from "react";
import { OpenWarningNotification } from "../../../../../components/notification/Notification";
import { useAppDispatch } from "../../../../../store/hooks";
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from "../../../../../components/input/upload-input/DragUpload";
import { formatBytes } from "../../../../../utils";
import { patchLocationMedia } from "../../../../../services/locations/Location.service";
import { UploadMedia } from "../../../../../services/media/Media.Upload";
import { LazyLoadImage } from "react-lazy-load-image-component";
import locationPage1 from "../../../../../assets/locationpage1.png";
import { FaUpload } from "react-icons/fa";
import { StyledLocationImageUploadContainer } from "./LocationImageUpload.styled";

interface Props {
  locationId: number;
  thumbnail: string;
  locationName: string;
}

export default function LocationImageUpload({
  locationId,
  thumbnail,
  locationName,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const validateFileType = (file: File, fileType?: string[]) => {
    if (!fileType) return true;
    const inputFileType: string = file?.type?.split("/")[0];
    if (inputFileType && fileType.includes(inputFileType)) {
      return true;
    } else {
      const msg = fileType.join(", ");
      OpenWarningNotification(`Only ${msg} file allowed!`);
      return false;
    }
  };
  const handleFileUpload = async (file: File) => {
    try {
      const response = await UploadMedia("Locations", file);
      if (response.Result.Id) {
        try {
          dispatch(
            patchLocationMedia({
              Id: locationId,
              MediaId: response.Result.Id,
            }),
          );
        } catch (error) {
          console.error("handleFileUpload Error:", error);
        }
      }
      console.log("handleFileUpload -> response:", response);
    } catch (error) {
      console.error("handleFileUpload Error:", error);
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (validateFileType(e.target.files[0], ["image"])) {
        if (e.target.files[0].size <= DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
          handleFileUpload(e.target.files[0]);
        } else {
          OpenWarningNotification(
            `File must smaller than ${formatBytes(
              DEFAULT_MAX_FILE_SIZE_IN_BYTES,
            )}`,
          );
        }
      }
    }
  };
  return (
    <StyledLocationImageUploadContainer
      onClick={() => {
        inputRef.current && inputRef.current.click();
      }}
    >
      <LazyLoadImage
        key={thumbnail}
        src={thumbnail || locationPage1}
        alt={locationName}
        effect="blur"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: "6%",
        }}
      />
      <div className="upload-overlay">
        <FaUpload />
      </div>
      <input
        ref={inputRef}
        type="file"
        id={`fileInput`}
        name="thumbnail_img"
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
      />
    </StyledLocationImageUploadContainer>
  );
}
