import React from "react";
import { StyledFaSync } from "../tables/Table.style";
import { Tooltip } from "react-tooltip";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";

interface SyncIconProps {
  handleSyncOnClick: () => void;
  size: string;
  tooltip?: string;
  disabled?: boolean;
  tooltipContent?: string;
}

const SyncIcon: React.FC<SyncIconProps> = ({
  handleSyncOnClick,
  size,
  tooltip,
  disabled,
  tooltipContent,
}) => {
  return (
    <CustomIconButton onClick={handleSyncOnClick}>
      <StyledFaSync
        data-tooltip-id="sync-tooltip"
        data-tooltip-content={tooltipContent || "Sync"}
        data-tooltip-place="top"
        size={size}
        className={disabled ? "disabled" : ""}
      />
      <Tooltip id="sync-tooltip" />
    </CustomIconButton>
  );
};

export default SyncIcon;
