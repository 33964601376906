import React, { useEffect, useState } from "react";
import { Typography } from "../../../typrography/Typography";
import { ScaledButtonWrapper } from "../../../modals/CustomModal.style";
import CustomModal from "../../../modals/CustomModal";
import { Relay, RelayWithValues } from "../../../../interfaces/device/Relay";
import { Formik } from "formik";
import { StyledFormikForm } from "../../ai-model-manager/Upload.style";
import * as Yup from "yup";
import Input from "../../../input/Input";
import { FormControl } from "../../../form-field/form-control/FormControl";
import relayService from "../../../../services/device/deviceRelays.service";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { updateRelay } from "../../../../store/slices/devices/devicesSlice";
import { FlexContainer } from "../../../flex-container/FlexContainer";

interface Props {
  relay: RelayWithValues;
  onClose: () => void;
}

interface FormValues {
  name: string;
}

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(1, "Name must be at least 1 character"),
});

export default function SetupRelayDialog({ relay, onClose }: Props) {
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) => state.devices);
  const selectedDevice = device.selectedDevice;
  useState<boolean>(false);
  const initialValues = {
    name: relay?.Name || "",
  };

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        Update Relay
      </Typography>
    );
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: any) => {
    const payload: Relay = {
      Id: relay.Id,
      DeviceId: relay.DeviceId,
      Active: relay.Active,
      PinNumber: relay.PinNumber,
      Name: values.name,
    };
    const response = await relayService.updateRelay(payload, dispatch);

    if (response !== null) {
      dispatch(
        updateRelay({
          relay: response.Result,
          masterDeviceId: selectedDevice?.Id || "",
        }),
      );
      onClose();
    }
  };

  return (
    <CustomModal
      isOpen={true}
      onClose={onClose}
      padding="1rem 0"
      width="40vw"
      height="45vh"
      header={cardHeader()}
    >
      <div style={{ padding: "0 1rem" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, handleChange }: any) => (
            <StyledFormikForm id="rename-relay">
              <Typography color="primary">Edit Relay Name</Typography>
              <FlexContainer flexDirection="column">
                <FormControl>
                  <Input
                    name="name"
                    placeHolder="Relay Name"
                    label={"Relay Name"}
                    width="100%"
                    inputWrapperWidth={"100%"}
                    value={values.name} // Now it will recognize values.name
                    onChange={handleChange}
                    hasErrorMessage={false}
                  />
                </FormControl>
                <ScaledButtonWrapper buttonPadding={"10px"}>
                  <button type="submit" form="rename-relay">
                    Rename
                  </button>
                </ScaledButtonWrapper>
              </FlexContainer>
            </StyledFormikForm>
          )}
        </Formik>
      </div>
    </CustomModal>
  );
}
