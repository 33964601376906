import React from "react";
import { StyledFiEdit } from "../tables/Table.style";
import { Tooltip } from "react-tooltip";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";

interface EditIconProps {
  handleEditOnClick: (arg0?: any) => void;
  size?: string;
  tooltip?: string;
  disabled?: boolean;
}

const EditIcon: React.FC<EditIconProps> = ({
  handleEditOnClick,
  size,
  tooltip,
  disabled,
}) => {
  return (
    <CustomIconButton onClick={handleEditOnClick}>
      <StyledFiEdit
        size={size}
        data-tooltip-id="edit-tooltip"
        data-tooltip-content={tooltip || "Edit"}
        data-tooltip-place="top"
        className={disabled ? "disabled" : ""}
      />
      <Tooltip id="edit-tooltip" />
    </CustomIconButton>
  );
};

export default EditIcon;
