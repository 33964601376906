import React, { useState } from "react";
import { Tooltip } from "react-tooltip";

import { IoClose } from "react-icons/io5";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";

interface RemoveIconProps {
  handleRemoveOnClick: () => void;
  size?: string;
  tooltip?: string;
}

const RetrainIcon: React.FC<RemoveIconProps> = ({
  handleRemoveOnClick,
  size,
  tooltip,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <CustomIconButton onClick={handleRemoveOnClick}>
      <IoClose
        style={{
          cursor: "pointer",
        }}
        size={size ? size : "2rem"}
        color={isHovered ? "#d64550" : "#FFF"}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        data-tooltip-id="remove-tooltip"
        data-tooltip-content={tooltip || "Remove"}
        data-tooltip-place="top"
      />
      <Tooltip id="remove-tooltip" />
    </CustomIconButton>
  );
};

export default RetrainIcon;
