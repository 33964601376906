import React, { useEffect, useState } from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "../../../../components/typrography/Typography";
import { StyledFontAwesomeIcon } from "../../../../components/device-manager-card/card-header/CardHeader.style";
import DeviceNameModal from "../../../../components/modals/edit-device-name-modal/EditDeviceNameModal";
import { IconWrapper } from "./DeviceName.style";
import { CustomIconButton } from "../../../../components/button/icon-button/CustomIconButton";
import { Tooltip } from "react-tooltip";

type Props = {
  masterDeviceId?: string;
  deviceId: string;
  deviceName: string;
  tooltip?: string;
  disabled?: boolean;
};

export default function EditDeviceName({
  masterDeviceId,
  deviceName,
  deviceId,
  tooltip,
  disabled,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectRelationModalOpen = () => {
    setIsOpen(true);
  };

  const handleSelectRelationModalClose = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto"; // enable scrolling
  };

  return (
    <>
      <CustomIconButton onClick={handleSelectRelationModalOpen}>
        <Typography variant="lg" color="white" weight="bold">
          <IconWrapper padding={"0.5rem"}>
            <StyledFontAwesomeIcon
              icon={faEdit}
              cursor="pointer"
              data-tooltip-id="edit-tooltip"
              data-tooltip-content={tooltip || "Edit"}
              data-tooltip-place="top"
              className={disabled ? "disabled" : ""}
            />
          </IconWrapper>
        </Typography>
        <Tooltip id="edit-tooltip" />
      </CustomIconButton>
      <DeviceNameModal
        isOpenModel={isOpen}
        deviceName={deviceName}
        deviceId={deviceId}
        masterDeviceId={masterDeviceId || ""}
        onClose={handleSelectRelationModalClose}
      />
    </>
  );
}
