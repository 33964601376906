import React, { useState } from "react";
import { GroupBox, GroupTitle } from "./Grouping.style";
import { CustomDropdown } from "../../../form-field/drop-down/CustomDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "../../../typrography/Typography";
import { postDeviceGroup } from "../../../../services/device/deviceGroups.service";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { OpenSuccessNotification } from "../../../notification/Notification";
import { setDeviceGroup } from "../../../../store/slices/devices/devicesSlice";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Group } from "../../../../interfaces/device/Group";
import {
  FilterDropdown,
  FilterOption,
} from "../../../dropdown/filter-dropdown/FilterDropdown";
import { CustomIconButton } from "../../../button/icon-button/CustomIconButton";

interface Props {
  locationGroups: Group[];
}
export default function AddDeviceGroup({ locationGroups }: Props) {
  const dispatch = useAppDispatch();
  const selectedDeviceId = useAppSelector(
    (state) => state.devices.selectedDevice.Id,
  );
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = () => {
    setIsEdit(true);
  };
  const cancelEdit = () => {
    setIsEdit(false);
  };
  const handleGroupDropdownChange = async (value: number) => {
    const response = await postDeviceGroup(
      {
        id: 0,
        deviceId: selectedDeviceId,
        groupId: value,
      },
      dispatch,
    );
    if (response !== null && !response.IsError) {
      cancelEdit();
      dispatch(setDeviceGroup(response.Result));
    }
  };
  return (
    <GroupBox>
      <GroupTitle>Add Group</GroupTitle>
      {isEdit ? (
        <FilterDropdown
          title="Select Group"
          options={locationGroups.map((option: Group) => ({
            label: option.Name,
            value: option.Id,
          }))}
          onChange={({ label, value }: FilterOption) => {
            handleGroupDropdownChange(Number(value));
          }}
        />
      ) : (
        <>
          <CustomIconButton onClick={() => handleEdit()}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{ padding: "0.725rem 0 0 0" }}
            />
          </CustomIconButton>
          <Typography
            variant="sm"
            weight="light"
            direction="center"
            padding="0.725rem 0"
          >
            Click Here!
          </Typography>
        </>
      )}
    </GroupBox>
  );
}
