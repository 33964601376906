import axios, { AxiosError, AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../authentication/auth.service";
import { ResponseObject } from "../../interfaces/response/Response";
import {
  validateErrorResult,
  validateFetchErrorResult,
  validateFetchSuccessResult,
  validateSuccessResult,
} from "../../utils/axiosUtils";
import { IntegrationCompany } from "../../store/slices/integration/integrationSlice";

const SWRM_BE_API_URL =
  process.env.REACT_APP_SWRMBE_URL + "api/v2/IntegrationCompany";

export interface AuthenticatePayload {
  Username: string;
  Password: string;
  IntegratedWith: string;
}

export interface CredentialsPayload {
  Username: string;
  Password: string;
}

export interface UpdateIntegrationStatusPayload {
  companyId: string;
  integrationId: number;
  status: boolean;
}

// SWRM Service Methods
const getIntegrations = createAsyncThunk(
  "integration/getIntegrations",
  async (_, { dispatch }) => {
    let response = await axios
      .get(`${SWRM_BE_API_URL}`)
      .then(
        async (response: AxiosResponse<ResponseObject<IntegrationCompany>>) => {
          // Validate Success Result
          const result = response.data;
          await validateFetchSuccessResult(response);

          return result;
        },
      )
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        return await validateFetchErrorResult(error, dispatch);
      });

    return response;
  },
);

const updateIntegrationStatus = createAsyncThunk(
  "integration/updateIntegrationStatus",
  async (payload: UpdateIntegrationStatusPayload, { dispatch }) => {
    const response = await axios
      .patch(
        `${SWRM_BE_API_URL}/updateStatus?companyId=${payload.companyId}
        &integrationId=${payload.integrationId}
        &status=${payload.status}`,
      )
      .then(async (response: AxiosResponse<ResponseObject<string>>) => {
        // Validate Success Result
        const result = response.data;
        const successMessage: string = "Changes saved.";
        await validateSuccessResult(response, dispatch, successMessage);

        return result;
      })
      .catch(async (error: AxiosError<ResponseObject<string>>) => {
        await dispatch(integrationService.getIntegrations());
        authService.setIntegrationAuthTokenToHeader("");
        authService.setIntegrationRefreshTokenToHeader("");
        return await validateErrorResult(error, dispatch);
      });

    return response;
  },
);

export const integrationService = {
  getIntegrations,
  updateIntegrationStatus,
};
