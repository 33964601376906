import React from "react";
import { IoSettingsSharp } from "react-icons/io5";
import {
  RelayBoxBlock,
  RelayEdit,
  RelaySwitch,
  RelayTitle,
} from "./RelayBox.style";
import { CustomIconButton } from "../../../button/icon-button/CustomIconButton";
interface Props {
  title: string;
  onEdit: () => void;
  children: React.ReactNode;
}
export default function RelayBox({ title, onEdit, children }: Props) {
  return (
    <RelayBoxBlock>
      <RelayEdit>
        <CustomIconButton onClick={onEdit}>
          <IoSettingsSharp />
        </CustomIconButton>
      </RelayEdit>
      <RelayTitle> {title}</RelayTitle>
      <RelaySwitch>{children}</RelaySwitch>
    </RelayBoxBlock>
  );
}
