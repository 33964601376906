import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleXmark,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { NavigationButton } from "../../../components/button/navigation-button/NavigationButton";
import { Chip } from "../../../components/chip/Chip";
import { ControlButtons } from "../../../components/device-manager-card/image-card/ImageCard.style";
import { FlexContainer } from "../../../components/flex-container/FlexContainer";
import { Image } from "../../../components/image/Image";
import { Typography } from "../../../components/typrography/Typography";
import { CustomInfoCard } from "../../../components/info-card/InfoCard.style";
import Permission from "./Permission";
import permissionpage1 from "../../../assets/permissionpage1.png";
import { ChipStyles } from "../../../enums/chip/ChipEnums";
import {
  UserCompany,
  UserRoles,
} from "../../../interfaces/authentication/User";
import { awsUtils } from "../../../utils/awsUtils";
export interface InfoCardProps {
  user: UserCompany;
  next?: any;
  prev?: any;
}

export const InfoCardPermission: React.FC<InfoCardProps> = ({
  user,
  next,
  prev,
}) => {
  const [imageUrl, setImageUrl] = useState<string>(permissionpage1); // Default image

  useEffect(() => {
    const fetchSignedImageURL = async () => {
      if (user.User?.Media?.Url) {
        const signedUrl = await awsUtils.getSignedImageURL(user.User.Media.Url);
        setImageUrl(signedUrl || permissionpage1);
      }
    };

    fetchSignedImageURL();
  }, [user.User?.Media?.Url]);
  return (
    <CustomInfoCard>
      {/* image */}
      <Image src={imageUrl} alt={user.User.FirstName || ""} />
      {/* <Image
        src={employee.Media?.PreSignedURL || permissionpage1}
        alt={employee.Name}
      /> */}
      {/* content */}
      <FlexContainer
        justifyContent="space-between"
        alignItems="flex-start"
        height="100%"
      >
        <FlexContainer
          flexDirection="column"
          justifyContent="stretch"
          alignItems="flex-start"
          gap="32px"
        >
          <FlexContainer
            justifyContent="flex-start"
            gap="12px"
            alignItems="flex-start"
          >
            {/* chip */}
            <ControlButtons>
              <Chip
                children={
                  UserRoles[user.RoleId] == "Owner"
                    ? ChipStyles.Owner
                    : UserRoles[user.RoleId] == "Editor"
                    ? ChipStyles.Editor
                    : ChipStyles.Viewer
                }
              />
            </ControlButtons>
            {/* the id */}
            <Typography variant="sm" weight="light">
              {UserRoles[user.RoleId] === "Owner"
                ? "Full Access"
                : "Restricted Access"}{" "}
              &nbsp;
              {
                <FontAwesomeIcon
                  icon={
                    UserRoles[user.RoleId] === "Owner"
                      ? faCheckCircle
                      : faCircleXmark
                  }
                  color={
                    UserRoles[user.RoleId] === "Owner" ? "#80D39B" : "#D64550"
                  }
                />
              }
            </Typography>
          </FlexContainer>

          {/* header and additional content */}
          <FlexContainer
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            {/* primary and secondary header */}
            <FlexContainer
              flexDirection="column"
              alignItems="flex-start"
              gap="12px"
            >
              <FlexContainer justifyContent="flex-start">
                <Typography variant="4xl" weight="bold">
                  {user.User.FirstName}
                </Typography>
              </FlexContainer>
            </FlexContainer>

            {/* MAke additional data container from this  */}

            <div
              style={{
                gap: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {/* <FlexContainer gap="8px">
                <Typography variant="md">
                  {" "}
                  <FontAwesomeIcon icon={faPhone} />
                </Typography>

                <Typography variant="md" color={"white"} weight="bold">
                  {employee.Phone}
                </Typography>
              </FlexContainer> */}
              <FlexContainer gap="8px">
                <Typography variant="md">
                  {" "}
                  <FontAwesomeIcon icon={faEnvelope} />
                </Typography>

                <Typography variant="md" color={"white"} weight="bold">
                  {user.User.Email}
                </Typography>
              </FlexContainer>
            </div>
            {user.RoleId !== UserRoles.Owner && (
              <div style={{ padding: "1rem 0" }}>
                <Permission data={user} />
              </div>
            )}
          </FlexContainer>
        </FlexContainer>
        {/* ------------------------------------------------------------------------- */}
        <FlexContainer
          justifyContent="space-between"
          flexDirection="column"
          alignItems="flex-end"
          height="100%"
        >
          <FlexContainer gap="4px">
            <NavigationButton onClick={prev}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </NavigationButton>
            <NavigationButton onClick={next}>
              <FontAwesomeIcon icon={faChevronRight} />
            </NavigationButton>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </CustomInfoCard>
  );
};
