import React from "react";
import { Tooltip } from "react-tooltip";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";
import { FiHelpCircle } from "react-icons/fi";
import styled from "styled-components";

export const StyledFiHelpCircle = styled(FiHelpCircle)`
  color: ${(props) => props.theme.colors["grey-500"]};
  font-size: ${(props) =>
    props.size ? props.size : props.theme.fontSizes["sm"]};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;
interface HelpIconProps {
  handleOnClick: (arg0?: any) => void;
  size?: string;
  tooltip?: string;
  disabled?: boolean;
}

const HelpIcon: React.FC<HelpIconProps> = ({
  handleOnClick,
  size,
  tooltip,
  disabled,
}) => {
  return (
    <CustomIconButton onClick={handleOnClick}>
      <StyledFiHelpCircle
        size={size}
        data-tooltip-id="help-tooltip"
        data-tooltip-content={tooltip || "Help"}
        data-tooltip-place="top"
        className={disabled ? "disabled" : ""}
      />
      <Tooltip id="help-tooltip" />
    </CustomIconButton>
  );
};

export default HelpIcon;
