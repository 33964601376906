import React, { useEffect, useState } from "react";
import { Location } from "../../../../interfaces/device/Location";
import { FlexContainer } from "../../../../components/flex-container/FlexContainer";
import { Typography } from "../../../../components/typrography/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditIcon from "../../../../components/icons/EditIcon";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  DropdownItem,
  getLocationsDropdown,
  setDropdownOptions,
} from "../../../../store/slices/dropdownOptions/dropdownListSlice";
import { RootState } from "../../../../store/store";
import { getAllLocations } from "../../../../services/locations/Location.service";
import SaveIcon from "../../../../components/icons/SaveIcon";
import { patchDeviceLocation } from "../../../../services/device/devicePatch.service";
import {
  FilterDropdown,
  FilterOption,
} from "../../../../components/dropdown/filter-dropdown/FilterDropdown";

type Props = {
  location: Location | null | undefined;
  deviceId: string;
  deployed: boolean;
};

export default function DeviceLocation({
  location,
  deviceId,
  deployed = false,
}: Props) {
  const dispatch = useAppDispatch();
  const locationDropdown = useAppSelector(getLocationsDropdown);
  const defaultValue: FilterOption = {
    label: location?.Name!,
    value: Number(location?.Id),
  };

  // Fetch all locations, devices, and AI models on initial load of the page
  useEffect(() => {
    if (
      !locationDropdown?.options.length ||
      !locationDropdown?.options.filter((x) => x.value).length
    ) {
      dispatch(getAllLocations({}));
    }
  }, []);
  const handleDropdownChange = ({ label, value }: FilterOption) => {
    if (value && value !== location?.Id) {
      dispatch(
        patchDeviceLocation({ deviceId: deviceId, locationId: Number(value) }),
      );
    }
  };
  return (
    <FlexContainer gap="0.5rem">
      <Typography variant="lg">
        <FontAwesomeIcon icon={faTruck} />
      </Typography>
      <Typography variant="xl">Location:</Typography>
      {!deployed ? (
        <>
          <Typography variant="md" color={"green-100"}>
            {location?.Name || "-"}
          </Typography>
        </>
      ) : (
        <FilterDropdown
          defaultValue={defaultValue}
          title="Location"
          options={locationDropdown?.options || []}
          onChange={({ label, value }: FilterOption) => {
            handleDropdownChange({ label, value });
          }}
        />
      )}
    </FlexContainer>
  );
}
