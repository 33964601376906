import { useState } from "react";
import { Typography } from "../../../../components/typrography/Typography";
import EditIcon from "../../../../components/icons/EditIcon";
import EditLocationInfoModal from "../../../../components/modals/edit-location-name-modal/EditLocationInfoModal";
import { Location } from "../../../../interfaces/device/Location";
import React from "react";

type Props = {
  location: Location;
};

const LocationEditToolTip = ({ location }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectRelationModalOpen = () => {
    setIsOpen(true);
  };

  const handleSelectRelationModalClose = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto"; // enable scrolling
  };

  return (
    <>
      <Typography variant="md" color="white" weight="bold">
        <EditIcon
          handleEditOnClick={handleSelectRelationModalOpen}
          size="26px"
        />
      </Typography>
      {isOpen && (
        <EditLocationInfoModal
          isOpenModel={isOpen}
          onClose={handleSelectRelationModalClose}
          location={location}
        />
      )}
    </>
  );
};

export default LocationEditToolTip;
