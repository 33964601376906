import React from "react";
import NavBarTitle from "../../title/nav-bar-title/NavBarTitle";
import { IconWrapper, SetupNavBarWrapper } from "./SetupNavBar.style";

interface SetupNavBarProps {
  title: string;
  subTitle: string;
  barIcon: JSX.Element;
  onClick: (value: string) => void;
}

const SetupNavBar: React.FC<SetupNavBarProps> = ({
  title,
  subTitle,
  barIcon,
  onClick,
}) => {
  return (
    <>
      <SetupNavBarWrapper onClick={() => onClick(title)}>
        <IconWrapper>{barIcon}</IconWrapper>
        <NavBarTitle title={title} subTitle={subTitle}></NavBarTitle>
      </SetupNavBarWrapper>
    </>
  );
};

export default SetupNavBar;
