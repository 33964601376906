import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { Location } from "../../../interfaces/device/Location";
import {
  getAllLocations,
  patchLocation,
  patchLocationMedia,
  patchLocationName,
  patchLocationAddress,
  searchLocations,
  searchLocationsFilter,
  locationSoftDelete,
  locationArchive,
} from "../../../services/locations/Location.service";
import { Group } from "../../../interfaces/device/Group";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../components/notification/Notification";
import LocationTypeService from "../../../services/locations/LocationType.service";
import { LocationType } from "../../../interfaces/device/LocationType";
import GroupTypeService from "../../../services/locations/GroupType.service";
import { awsUtils } from "../../../utils/awsUtils";

//Define a type for the slice state
interface LocationState {
  data: Location[];
  allLocationsForModal: Location[] | [];
  locationNames: string[];
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
  activeLocationId: number;
  groups: Group[];
  locationType: LocationType[];
  groupOptions: Group[];
}
export const defaultLocation: Location = {
  Id: 0,
  StoreID: 0,
  ClientID: 0,
  // Client: Client,
  ContactLastName: "",
  ContactFirstName: "",
  Address: "",
  City: "",
  PostalCode: "",
  Country: "",
  GPSLongitude: 0,
  GPSLatitude: 0,
  Thumbnail: "",
  LocationTypeId: 0,
  // LocationType: LocationType,
  Enabled: false,
  Operating: false,
  Name: "",
  Description: "",
  MediaId: 0,
  Groups: [],
};
const initialState: LocationState = {
  data: [],
  allLocationsForModal: [],
  locationNames: [],
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
  activeLocationId: 0,
  groups: [],
  locationType: [],
  groupOptions: [],
};

export const locationsSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setAllLocations: (state, action: PayloadAction<Location[]>) => {
      state.data = action.payload;
    },
    setActiveLocationImageCard: (state, action: PayloadAction<number>) => {
      state.activeLocationId = action.payload;
    },
    resetLocationImageCard: (state) => {
      state.activeLocationId = 0;
    },
    updateLocationDetails: (state, action: PayloadAction<Location>) => {
      const dataIndex = state.data.findIndex((x) => x.Id === action.payload.Id);
      if (dataIndex !== -1) {
        state.data[dataIndex] = action.payload;
      }
    },
    updateLocationGroups: (
      state,
      action: PayloadAction<{
        locationId: number;
        groupID: number;
        group: Group;
      }>,
    ) => {
      state.data.forEach((location) => {
        if (action.payload.locationId === location.Id) {
          location.Groups.push(action.payload.group);
        } else {
          const groupIndex = location.Groups.findIndex(
            (g: Group) => g.Id === action.payload.groupID,
          );
          if (groupIndex !== -1) {
            location.Groups.splice(groupIndex, 1);
          }
        }
      });
    },
    setAllGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
    removeGroupFromLocation: (state, action: PayloadAction<Group>) => {
      const index = state.data.findIndex(
        (l) => l.Id === state.activeLocationId,
      );
      if (index !== -1) {
        const locationGroupIndex = state.data[index].Groups.findIndex(
          (g) => g.Id === action.payload.Id,
        );
        if (locationGroupIndex !== -1) {
          state.data[index].Groups.splice(locationGroupIndex, 1);
        }
      }
      const groupIndex = state.groups.findIndex(
        (g) => g.Id === action.payload.Id,
      );
      if (groupIndex !== -1) {
        state.groups[groupIndex] = action.payload;
      }
    },
  },
  extraReducers: (build) => {
    build.addCase(getAllLocations.pending, (state, action) => {
      state.isLoading = true;
    });
    build.addCase(getAllLocations.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload && Array.isArray(action.payload)) {
        state.data = [...action.payload];
        state.allLocationsForModal = [...action.payload];
      }
    });
    build.addCase(getAllLocations.rejected, (state, action) => {
      state.isLoading = false;
    });
    build.addCase(
      LocationTypeService.getAllLocationsType.fulfilled,
      (state, action) => {
        if (action.payload.Result && Array.isArray(action.payload.Result)) {
          state.locationType = [...action.payload.Result];
        }
      },
    );
    build.addCase(GroupTypeService.getAllGroups.fulfilled, (state, action) => {
      if (action.payload.Result && Array.isArray(action.payload.Result)) {
        state.groupOptions = [...action.payload.Result];
      }
    });
    build.addCase(searchLocations.fulfilled, (state, action) => {
      let lowerCaseLocations = action.payload.Result.map((l: string) =>
        l.toLowerCase(),
      );
      state.locationNames = [...lowerCaseLocations];
    });
    build.addCase(searchLocationsFilter.fulfilled, (state, action) => {
      if (action.payload === undefined) {
        state.allLocationsForModal.splice(0, state.allLocationsForModal.length);
      } else {
        state.allLocationsForModal = [...(action.payload as Location[])];
      }
    });
    build.addCase(patchLocationMedia.fulfilled, (state, action) => {
      const dataIndex = state.data.findIndex(
        (x) => x.Id === (action.payload as Location).Id,
      );
      if (dataIndex !== -1) {
        state.data[dataIndex] = {
          ...state.data[dataIndex],
          ...(action.payload as Location[]),
        };

        // state.data = [...action.payload as Location];
      }
    });
    build.addCase(patchLocation.fulfilled, (state, action) => {
      const dataIndex = state.data.findIndex(
        (x) => x.Id === action.payload.Result.Id,
      );
      if (dataIndex !== -1) {
        state.data[dataIndex] = {
          ...state.data[dataIndex],
          ...action.payload.Result,
        };
      }
    });
    build.addCase(patchLocationName.fulfilled, (state, action) => {
      if (!action.payload.IsError) {
        OpenSuccessNotification("Location name updated");
        const dataIndex = state.data.findIndex(
          (x) => x.Id === action.payload.Result.Id,
        );
        if (dataIndex !== -1) {
          state.data[dataIndex] = {
            ...state.data[dataIndex],
            ...action.payload.Result,
          };
        }
      } else {
        OpenErrorNotification(action.payload.ErrorMessage);
      }
    });
    build.addCase(patchLocationAddress.fulfilled, (state, action) => {
      if (!action.payload.IsError) {
        OpenSuccessNotification("Location address updated");
        const dataIndex = state.data.findIndex(
          (x) => x.Id === action.payload.Result.Id,
        );
        if (dataIndex !== -1) {
          state.data[dataIndex] = {
            ...state.data[dataIndex],
            ...action.payload.Result,
          };
        }
      } else {
        OpenErrorNotification(action.payload.ErrorMessage);
      }
    });
    build.addCase(locationSoftDelete.fulfilled, (state, action) => {
      if (!action.payload.IsError) {
        OpenSuccessNotification("Location has been deleted");
        const filteredLocations = state.data.filter((location) => {
          return location.Id !== action.payload.Result.Id;
        });
        state.data = filteredLocations;
      } else {
        OpenErrorNotification(action.payload.ErrorMessage);
      }
    });
    build.addCase(locationArchive.fulfilled, (state, action) => {
      if (!action.payload.IsError) {
        OpenSuccessNotification("Location has been archived");
        const filteredLocations = state.data.filter((location) => {
          return location.Id !== action.payload.Result.Id;
        });
        state.data = filteredLocations;
      } else {
        OpenErrorNotification(action.payload.ErrorMessage);
      }
    });
  },
});

export const {
  setAllLocations,
  updateLocationGroups,
  setActiveLocationImageCard,
  resetLocationImageCard,
  setAllGroups,
  removeGroupFromLocation,
  updateLocationDetails,
} = locationsSlice.actions;

export const selectLocations = (state: RootState) => state.locations;
export const allLocationNames = (state: RootState) =>
  state.locations.locationNames;
export const isLocationLoading = (state: RootState) =>
  state.locations.isLoading;

export default locationsSlice.reducer;
