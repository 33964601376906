import styled from "styled-components";

export const CustomInfoCard = styled.div`
  max-width: 100%;
  width: 100%;
  display: grid;
  background-color: #020a01;
  color: white;
  margin-bottom: 20px;
  @media (min-width: 1028px) {
    grid-template-columns: 20% auto;
    gap: 25px;
  }
`;

export const CustomInfoCardSingleColumn = styled(CustomInfoCard)`
  margin-top: 20px;
  grid-template-columns: 100%;
  @media (min-width: 1028px) {
    grid-template-columns: 100%;
  }
  @media (max-width: 600px) {
    grid-template-rows: 1fr 1fr;
  }
`;
