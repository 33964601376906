import { FlexContainer } from "../../../components/flex-container/FlexContainer";
import { LocationList } from "../location-list/LocationList";
import { ModalProvider } from "styled-react-modal";
import Modal from "../../../components/modals/Modals";
import { resetDeviceStatusRadio } from "../../../components/radio-button/radioSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { resetSearchParams } from "../../../store/slices/fetchParameters/fetchParametersSlice";
import { resetSortByState } from "../../../components/sort-by/sortBySlice";
import LocationFilterOptions from "../../../components/filter-options/LocationFilterOptions";
import { isLocationLoading } from "../../../store/slices/locations/locationsSlice";
import Loader from "../../../components/loader/Loader";
import LocationCard from "../LocationCard";
import { Location } from "../../../interfaces/device/Location";
import { ImageCardsWrapper } from "../../../components/modals/Modals.style";
import React from "react";

export const Manage = () => {
  const dispatch = useAppDispatch();
  const locationList = useAppSelector(
    (state) => state.locations.allLocationsForModal,
  );
  const isLoading = useAppSelector(isLocationLoading);
  const onClose = () => {
    dispatch(resetDeviceStatusRadio());
    dispatch(resetSearchParams({ page: "Locations" }));
    dispatch(resetSortByState());
  };

  return (
    <FlexContainer
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <ModalProvider>
        <Modal
          buttonLabel={"Filter Locations"}
          listTitle="Location List"
          onClose={onClose}
        >
          <>
            <LocationFilterOptions />
            <ImageCardsWrapper>
              {locationList.map((location: Location, index: number) => (
                <LocationCard
                  key={location.Id}
                  location={location}
                  onClick={() => undefined}
                />
              ))}
            </ImageCardsWrapper>
          </>
        </Modal>
      </ModalProvider>
      {isLoading && <Loader isLoadingScreen />}
      <LocationList />
    </FlexContainer>
  );
};
