import React, { useState } from "react";
import {
  NotificationBarWrapper,
  StyledBsCheckCircleFill,
  StyledBsFillExclamationCircleFill,
  StyledBsFillExclamationTriangleFill,
  StyledIoClose,
  TextWrapper,
} from "./NotificationBar.style";
import { INotificationData } from "../../data/notificationList";
import { Typography } from "../typrography/Typography";
import { CustomIconButton } from "../button/icon-button/CustomIconButton";

export interface NotificationBarProps {
  notification: INotificationData;
}

const NotificationBar = ({ notification }: NotificationBarProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const options: any = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const handleCloseNotificationBar = (id: number) => {
    setIsVisible(false);
  };
  return (
    <>
      {isVisible && (
        <NotificationBarWrapper notification={notification}>
          {notification.priority === "high" ? (
            <StyledBsFillExclamationCircleFill />
          ) : notification.priority === "medium" ? (
            <StyledBsFillExclamationTriangleFill />
          ) : (
            <StyledBsCheckCircleFill />
          )}
          <TextWrapper>
            <Typography>{notification.description}</Typography>
            <Typography variant="xs">
              {notification.createdTime.toLocaleString("en-CA", options)}
            </Typography>
          </TextWrapper>
          <CustomIconButton
            onClick={() => handleCloseNotificationBar(notification.id)}
          >
            <StyledIoClose />
          </CustomIconButton>
        </NotificationBarWrapper>
      )}
    </>
  );
};

export default NotificationBar;
