import styled from "styled-components";
import { DefaultThemeBorder, DefaultThemeFontSize } from "../../../app/Theme";

export const FilterDropdownsWrapper = styled.div`
  display: flex;
  gap: 12px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
`;

export const FilterDropdownWrapper = styled.div<{ margin?: string }>`
  margin: ${({ margin }) => (margin ? margin : 0)};
`;

// Select component from react-select package styling
export const filterDropdownStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    height: "2.25rem",
    width: "10rem",
    backgroundColor: "#020A01",
    border: DefaultThemeBorder["border-sm-yellow"],
    borderRadius: "5px",
    fontSize: DefaultThemeFontSize.sm,
    boxShadow: "none",
    cursor: "pointer",

    "&:hover": {
      borderColor: "#00916E",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#020A01",
    border: DefaultThemeBorder["border-sm-yellow"],
    borderRadius: "5px",
    outline: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#FFF",
    cursor: "pointer",
    backgroundColor: state.isSelected && "#EDAE49",

    "&:hover": {
      backgroundColor: "#EDAE49",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
};

export const filterDropdownStyleFull = {
  control: (provided: any, state: any) => ({
    ...provided,
    height: "2.25rem",
    backgroundColor: "#020A01",
    border: DefaultThemeBorder["border-sm-yellow"],
    borderRadius: "5px",
    fontSize: DefaultThemeFontSize.sm,
    boxShadow: "none",
    cursor: "pointer",

    "&:hover": {
      borderColor: "#00916E",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#020A01",
    border: DefaultThemeBorder["border-sm-yellow"],
    borderRadius: "5px",
    outline: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#FFF",
    cursor: "pointer",
    backgroundColor: state.isSelected && "#EDAE49",

    "&:hover": {
      backgroundColor: "#EDAE49",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
};
