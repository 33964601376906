import React from "react";

//Icons
import { faEdit } from "@fortawesome/free-solid-svg-icons";

//Custom Components
import { Typography } from "../../typrography/Typography";

//Types
import { CardType } from "../../../interfaces/cardTypes/card-types";
import { StyledFontAwesomeIcon } from "./CardHeader.style";
import { CustomIconButton } from "../../button/icon-button/CustomIconButton";

interface Props {
  cardType: CardType;
  onClick?: (id: string) => void;
  onMouseOver?: () => void;
}

export const CardHeader: React.FC<Props> = ({
  cardType,
  onClick,
  onMouseOver,
}) => {
  const {
    header,
    edit,
    index,
    padding,
    helperLabel,
    mainLabel,
    mainLabelSpan,
    additionalIcon,
    image,
    hideHeader = false,
  } = cardType;

  return (
    <>
      {!image && !hideHeader ? (
        edit ? (
          <Typography display="flex" direction="flex-end">
            <Typography variant="md" color="white" weight="bold">
              {additionalIcon}
            </Typography>
            {"\u00a0"}
            <CustomIconButton
              onClick={onClick ? () => onClick(index) : () => {}}
              onMouseOver={onMouseOver ? () => onMouseOver() : () => {}}
            >
              <Typography variant="md" color="white" weight="bold">
                <StyledFontAwesomeIcon icon={faEdit} />
              </Typography>
            </CustomIconButton>
          </Typography>
        ) : (
          <Typography variant="md" color="white" weight="bold">
            {"\u00a0"}
          </Typography>
        )
      ) : null}
      <Typography
        variant="lg"
        color="white"
        weight="light"
        style={{ textAlign: "center" }}
      >
        {header}
      </Typography>
      <div>
        <Typography direction="column" display="flex">
          {mainLabelSpan ? (
            <Typography variant="3xl" color="white" weight="bold">
              {mainLabel}
              <Typography
                variant="lg"
                color="white"
                weight="bold"
                display="inline"
              >
                {mainLabelSpan}
              </Typography>
            </Typography>
          ) : (
            <Typography
              variant="2xl"
              color="white"
              weight="bold"
              padding={padding}
            >
              {mainLabel}
            </Typography>
          )}

          <Typography variant="sm" weight="light" direction="center">
            {helperLabel}
          </Typography>
        </Typography>
      </div>
    </>
  );
};
