import React, { useState, useEffect } from "react";
import {
  faBuilding,
  faChevronLeft,
  faChevronRight,
  faLayerGroup,
  faLocation,
  faTruck,
  faLocationCrosshairs,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavigationButton } from "../../../../components/button/navigation-button/NavigationButton";
import { FlexContainer } from "../../../../components/flex-container/FlexContainer";
import { Typography } from "../../../../components/typrography/Typography";
import {
  CustomInfoCard,
  CustomInfoCardSingleColumn,
} from "../../../../components/info-card/InfoCard.style";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getLocationTypeDropdown } from "../../../../store/slices/dropdownOptions/dropdownListSlice";
import { RootState } from "../../../../store/store";
import LocationService, {
  getAllLocationsType,
  patchLocation,
} from "../../../../services/locations/Location.service";
import GroupService from "../../../../services/locations/Group.service";
import ToggleButton from "../../../../components/button/toggle-button/ToggleButton";
import { Location } from "../../../../interfaces/device/Location";
import { Group } from "../../../../interfaces/device/Group";
import {
  setAllGroups,
  updateLocationGroups,
} from "../../../../store/slices/locations/locationsSlice";
import LocationImageUpload from "./location-image-upload/LocationImageUpload";
import LocationGroups from "./Groups/LocationGroups";
import { ResponseObject } from "../../../../interfaces/response/Response";
import LocationDevicesMap from "../../../../components/map/LocationDevicesMap";
import { DeviceMarker } from "../../../../interfaces/mapMarkers/DeviceMarker";
import { SwrmDevices } from "../../../../interfaces/device/SwrmDevice";
import { getLastScannedSensorData } from "../../../../services/dashboard/dashboard.service";
import Loader from "../../../../components/loader/Loader";
import { DefaultThemeColor } from "../../../../app/Theme";
import LocationToolTipGroup from "./LocationToolTipGroup";
import LocationEditToolTip from "./LocationEditToolTip";
import HelpIcon from "../../../../components/icons/HelpButtonIcon";
import CustomModal from "../../../../components/modals/CustomModal";

interface Props {
  location: Location;
  next: any;
  prev: any;
}
export const InfoCardLocation: React.FC<Props> = ({ location, next, prev }) => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state: RootState) => state.locations.groups);
  const devices = useAppSelector((state: RootState) => state.devices.data);

  const locationTypeDropdown = useAppSelector(getLocationTypeDropdown);
  const allLocations = useAppSelector(
    (state: RootState) => state.locations.data,
  );

  /* States */
  const [isLoadingLocationData, setIsLoadingLocationData] =
    useState<boolean>(true);
  const [deviceMarkers, setDeviceMarkers] = useState<DeviceMarker[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    new Array(5).fill(0),
  );
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  /* Methods */
  const handleGroupDropdownChange = async (
    selectedOptionId: number,
    selectedOption: any,
  ) => {
    try {
      LocationService.patchGroup({
        id: location.Id,
        groupID: selectedOptionId,
      })
        .then((response: any) => {
          // update Selected option
          setSelectedOptions(new Array(5).fill(0));
          // update state with value
          dispatch(
            updateLocationGroups({
              locationId: location.Id,
              groupID: selectedOptionId,
              group: response.Result,
            }),
          );
          // remove group from dropdown
          dispatch(
            setAllGroups(
              groups.filter((g: Group) => g.Id !== selectedOptionId),
            ),
          );
        })
        .catch((error: unknown) => {
          console.error("API call error:", error);
        });
    } catch (error) {
      console.error("Error updating enabled status:", error);
    }
  };

  const handleToggleChange = (newState: boolean, revert: () => void) => {
    try {
      const locationObj: Location | undefined = allLocations.find(
        (l: Location) => l.Id === location.Id,
      );
      if (locationObj) {
        handleLocationUpdate({ ...locationObj, Enabled: newState }, revert);
      }
    } catch (error) {
      console.error("Error updating enabled status:", error);
    }
  };

  const handleLocationUpdate = async (
    locationObj?: Location,
    revert?: () => void,
  ) => {
    if (locationObj) {
      const result = await dispatch(patchLocation(locationObj));
      const response: ResponseObject<Location> = result.payload;

      if (response.IsError) revert!();
    }
  };

  const fetchLocationData = async () => {
    // Fetch SWRM Devices Sensor Data
    const swrmResponse: ResponseObject<any[]> = await getLastScannedSensorData(
      location.Id,
      "",
    );

    // Fetch Location, Device, and AI Model data
    await dispatch(getAllLocationsType({}));
    GroupService.getGroups({}).then((response: any) => {
      if (response?.Result) {
        dispatch(
          setAllGroups(response?.Result.filter((g: Group) => !g.LocationId)),
        );
      }
    });

    setSelectedOptions((prevState) =>
      selectedOptions.map((o, i) =>
        Array.isArray(location.Groups) && location.Groups.length > i
          ? location.Groups[i].Id
          : selectedOptions[i],
      ),
    );

    // Set Device Markers
    const devicesOfLocation: SwrmDevices[] = devices.filter((device) => {
      return device.Location?.Id === location.Id;
    });

    const deviceMarkersOfLocation: DeviceMarker[] = devicesOfLocation.map(
      (device) => {
        return {
          Device: device,
          DeviceSensorData: {
            SWRMSensorData: swrmResponse.Result,
          },
        };
      },
    );
    setDeviceMarkers(deviceMarkersOfLocation);
    setIsLoadingLocationData(false);
  };
  const handleHelpOpen = () => {
    setIsHelpOpen(true);
  };
  const onClose = () => {
    setIsHelpOpen(false);
  };
  // Fetch location data
  useEffect(() => {
    fetchLocationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  return (
    <>
      {isLoadingLocationData ? (
        <FlexContainer width="100%" justifyContent="center">
          <Loader loadingText="Loading location data" />
        </FlexContainer>
      ) : (
        <>
          <CustomInfoCard>
            <LocationImageUpload
              locationId={location.Id}
              thumbnail={location.Thumbnail || ""}
              locationName={location.Name}
            />
            <FlexContainer
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
            >
              <FlexContainer
                flexDirection="column"
                justifyContent="stretch"
                alignItems="flex-start"
                gap="8px"
                margin="20px 0"
              >
                <Typography variant="4xl" weight="bold" direction="none">
                  {location.Name}
                </Typography>
                <FlexContainer
                  flexDirection="row"
                  justifyContent="start"
                  gap="10px"
                >
                  <ToggleButton
                    isActive={location.Enabled}
                    onToggle={(status, revert) =>
                      handleToggleChange(status, revert)
                    }
                    margin="0"
                  />
                  <Typography
                    style={{
                      color: location.Enabled
                        ? DefaultThemeColor["green-100"]
                        : DefaultThemeColor["red-100"],
                    }}
                  >
                    {location.Enabled ? "Enabled" : "Disabled"}
                  </Typography>
                  <LocationEditToolTip location={location} />
                  <LocationToolTipGroup locationId={location.Id} />
                </FlexContainer>
                {location.LocationTypeId &&
                  locationTypeDropdown &&
                  locationTypeDropdown.options && (
                    <Typography
                      variant="md"
                      weight="bold"
                      style={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        gap: "9px",
                        paddingLeft: "3px",
                      }}
                      direction="none"
                    >
                      {locationTypeDropdown.options.find(
                        (l) => l.value === location.LocationTypeId,
                      )?.label === "Vehicle" ? (
                        <FontAwesomeIcon icon={faTruck} />
                      ) : locationTypeDropdown.options.find(
                          (l) => l.value === location.LocationTypeId,
                        )?.label === "Job Site" ? (
                        <FontAwesomeIcon icon={faLocation} />
                      ) : locationTypeDropdown.options.find(
                          (l) => l.value === location.LocationTypeId,
                        )?.label === "Building" ? (
                        <FontAwesomeIcon icon={faBuilding} />
                      ) : null}
                      <span>
                        {
                          locationTypeDropdown.options.find(
                            (l) => l.value === location.LocationTypeId,
                          )?.label
                        }
                      </span>
                    </Typography>
                  )}
                {location?.Groups &&
                  locationTypeDropdown &&
                  [...Array(locationTypeDropdown.options)].map(
                    (group, index) => {
                      return (
                        <Typography
                          key={index}
                          variant="md"
                          style={{
                            display: "flex",
                            whiteSpace: "nowrap",
                            gap: "5px",
                          }}
                          direction="none"
                        >
                          <FontAwesomeIcon icon={faLayerGroup} />
                          <div>
                            <span style={{ fontWeight: "bold" }}>
                              Groups:&nbsp;
                            </span>
                            <span
                              style={{
                                color: DefaultThemeColor["green-100"],
                              }}
                            >
                              {Array.isArray(location.Groups)
                                ? location.Groups.map((item) =>
                                    item.Name ? item.Name : "",
                                  ).join(", ")
                                : location?.Groups}
                            </span>
                          </div>
                        </Typography>
                      );
                    },
                  )}
                <FlexContainer
                  flexDirection="column"
                  justifyContent="stretch"
                  alignItems="flex-start"
                  gap="8px"
                  margin="50px 0 0 0"
                >
                  <Typography
                    variant="xs"
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      gap: "5px",
                    }}
                    direction="none"
                  >
                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                    <div>
                      <span style={{ fontWeight: "bold", paddingLeft: "7px" }}>
                        GPS:
                        {location.GPSLatitude != null &&
                        location.GPSLongitude != null
                          ? location.GPSLatitude.toFixed(7) +
                            ", " +
                            location.GPSLongitude.toFixed(7)
                          : "-"}
                      </span>
                    </div>
                  </Typography>

                  <Typography
                    variant="md"
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                    }}
                    direction="none"
                  >
                    <span>{location.Address}</span>
                  </Typography>
                </FlexContainer>
              </FlexContainer>

              {/* navigation buttons */}
              <div>
                <FlexContainer
                  justifyContent="space-between"
                  flexDirection="column"
                  alignItems="flex-end"
                  height="100%"
                  margin="10px 0 0 0"
                  gap="4px"
                >
                  <FlexContainer gap="4px">
                    <NavigationButton onClick={prev}>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </NavigationButton>
                    <NavigationButton onClick={next}>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </NavigationButton>
                  </FlexContainer>
                  <HelpIcon size="32px" handleOnClick={handleHelpOpen} />
                </FlexContainer>
              </div>
            </FlexContainer>
          </CustomInfoCard>

          {/* location devices map */}
          {location.GPSLatitude !== null &&
            location.GPSLongitude !== null &&
            deviceMarkers.length !== 0 && (
              <LocationDevicesMap
                position={[location.GPSLatitude, location.GPSLongitude]}
                markers={deviceMarkers}
              />
            )}

          {/* manage cards */}
          <CustomInfoCardSingleColumn>
            <FlexContainer
              alignItems="flex-start"
              justifyContent="space-around"
              height="100%"
              flexDirection="column"
            >
              <LocationGroups
                locationGroups={location.Groups || []}
                groups={groups}
                selectedOptions={selectedOptions}
                handleGroupDropdownChange={handleGroupDropdownChange}
              />
            </FlexContainer>
          </CustomInfoCardSingleColumn>
          <CustomModal
            isOpen={isHelpOpen}
            onClose={onClose}
            padding="1rem 0"
            width="50%"
            height="auto"
            header={
              <Typography
                variant="lg"
                direction="flex-start"
                style={{ padding: "0 20px", fontWeight: "700" }}
              >
                Locations
              </Typography>
            }
          >
            <div>
              <Typography variant="lg" color="primary" padding={"0.5rem"}>
                This is the Locations tab
              </Typography>
              <Typography variant="md" padding={"0.5rem"}>
                All locations that are not deleted or archived are shown here.
              </Typography>
              <Typography variant="md" padding={"0.5rem"}>
                A location's information can be edited, icon swapped, archived
                or deleted. If the location is deleted it will no appear again
                and the name can be used again.
              </Typography>
              <Typography variant="md" padding={"0.5rem"}>
                If the location has GPS co-ordinates and also a device with gps
                co-ordinates deployed to that location a map will be shown on
                the map.
              </Typography>
              <Typography variant="md" padding={"0.5rem"}>
                Groups can be assigned to a location to separate the data into
                smaller areas
              </Typography>
              {/* <Typography variant="md" padding={"0.5rem"}>
            The location can be toggled disabled or enabled.
            When the location is disabled no data will be displayed from that location.
          </Typography> */}
            </div>
          </CustomModal>
        </>
      )}
    </>
  );
};
