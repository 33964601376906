import React from "react";
import { CustomFormLabel } from "./FormLabel.style";

export interface FormLabelProps {
  children: string;
  htmlFor: string;
  fontSize?: string;
  fontWeight?: string;
  padding?: string;
  margin?: string;
  style?: React.CSSProperties;
}

// form label component
export const FormLabel: React.FC<FormLabelProps> = ({
  children,
  htmlFor,
  fontSize,
  fontWeight,
  padding,
  style,
  margin,
}) => {
  return (
    <CustomFormLabel
      htmlFor={htmlFor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      padding={padding}
      margin={margin}
      style={style}
    >
      {children}
    </CustomFormLabel>
  );
};
