import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CaptureWithGroups } from "../../../interfaces/capture/Capture";
import {
  archiveCapture,
  getAllCaptures,
  searchCaptures,
  softDeleteCapture,
} from "../../../services/captures/capture.service";
import { RootState } from "../../store";
import { ICaptureImageCard } from "../../../interfaces/cardTypes/captures";
import { OpenErrorNotification } from "../../../components/notification/Notification";
import { ResponseObject } from "../../../interfaces/response/Response";

interface CaptureState {
  combinedCaptures: ICaptureImageCard[];
  isLoading: boolean;
  isSucceeded: boolean;
  errorMessage: string;
  archiveStatus: "idle" | "succeeded" | "loading" | "failed";
  archiveStatusMessage: string;
  deleteStatus: "idle" | "succeeded" | "loading" | "failed";
}

const initialState: CaptureState = {
  combinedCaptures: [] as ICaptureImageCard[],
  isLoading: false,
  isSucceeded: false,
  errorMessage: "",
  archiveStatus: "idle",
  archiveStatusMessage: "",
  deleteStatus: "idle",
};

export const captureSlice = createSlice({
  name: "capture",
  initialState,
  reducers: {
    resetAllCheckedStatus: (state) => {
      const updatedCombinedCaptures = state.combinedCaptures.map((capture) => ({
        ...capture,
        checked: false,
      }));

      state.combinedCaptures = updatedCombinedCaptures;
    },
    setCheckedStatus: (
      state,
      action: PayloadAction<{ captureId: number; checked: boolean }>,
    ) => {
      // Update checked status in combainedCaptures state
      const { captureId, checked } = action.payload;
      const selectedCapture = state.combinedCaptures.find(
        (capture) => capture.id === captureId,
      );

      if (selectedCapture) selectedCapture.checked = checked;
    },
  },
  extraReducers: (build) => {
    build.addCase(getAllCaptures.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(
      getAllCaptures.fulfilled,
      (
        state,
        action: PayloadAction<ICaptureImageCard[] | null | undefined>,
      ) => {
        state.isLoading = false;

        if (action.payload !== null && action.payload !== undefined) {
          state.isSucceeded = true;
          state.combinedCaptures = action.payload;
        }
      },
    );
    build.addCase(archiveCapture.pending, (state) => {
      state.archiveStatus = "loading";
    });
    build.addCase(
      archiveCapture.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<string> | null | undefined>,
      ) => {
        if (action.payload !== null && action.payload !== undefined) {
          //Update capture list new archived and non-archived captures
          state.archiveStatus = "succeeded";
          const captureId: number = Number(action.payload.Result);
          state.combinedCaptures = state.combinedCaptures.filter(
            (capture) => capture.id !== captureId,
          );
        }
      },
    );
    build.addCase(searchCaptures.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(
      searchCaptures.fulfilled,
      (
        state,
        action: PayloadAction<ICaptureImageCard[] | null | undefined>,
      ) => {
        state.isLoading = false;

        if (action.payload !== null && action.payload !== undefined) {
          state.isSucceeded = true;
          state.combinedCaptures = [...action.payload];
        }
      },
    );
    build.addCase(searchCaptures.rejected, (state, action) => {
      if (action.error.message) state.errorMessage = action.error.message;
      //Open error notification when archived is failed.
      OpenErrorNotification(state.errorMessage);
    });
    build.addCase(softDeleteCapture.pending, (state) => {
      state.deleteStatus = "loading";
    });
    build.addCase(
      softDeleteCapture.fulfilled,
      (
        state,
        action: PayloadAction<ResponseObject<string> | null | undefined>,
      ) => {
        state.deleteStatus = "succeeded";

        if (action.payload !== null && action.payload !== undefined) {
          //Update capture list with all non-deleted captures
          const deletedCaptureId: number = Number(action.payload.Result);
          state.combinedCaptures = state.combinedCaptures.filter(
            (capture) => capture.id !== deletedCaptureId,
          );
        }
      },
    );
    build.addCase(softDeleteCapture.rejected, (state, action) => {
      state.archiveStatus = "failed";
      if (action.error.message)
        //Open error notification when deleting is failed.
        OpenErrorNotification(action.error.message);
    });
  },
});

export const { resetAllCheckedStatus, setCheckedStatus } = captureSlice.actions;
export const selectedCaptureData = (state: RootState) => state.captures;
export const selectedCheckedCaptures = (state: RootState) =>
  state.captures.combinedCaptures.filter(
    (capture: ICaptureImageCard) => capture.checked === true,
  );

export default captureSlice.reducer;
