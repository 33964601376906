import { useEffect, useState } from "react";
import {
  RelayAccordionContainer,
  RelayAccordionItem,
  RelayAccordionHeader,
  RelayAccordionContent,
  SetupAddRuleTimerHeading,
} from "./RelayBox.style";
import {
  RelayRule,
  RelayRuleAction,
  RelayWithValues,
  RuleComparison,
} from "../../../../interfaces/device/Relay";
import CustomModal from "../../../modals/CustomModal";
import { Typography } from "../../../typrography/Typography";
import { BiMinus, BiPlus } from "react-icons/bi";
import relayService from "../../../../services/device/deviceRelays.service";
import { useAppDispatch } from "../../../../store/hooks";
import RelayRuleItem from "./RelayRuleItem";
import { SwrmDevices } from "../../../../interfaces/device/SwrmDevice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddRelayRuleModal from "./AddRelayRuleModal";
import { FilterOption } from "../../../dropdown/filter-dropdown/FilterDropdown";
import EditRelayRuleModal from "./EditRelayRuleModal";
import ConfirmationModal from "../../../modals/confirmation-modal/ConfirmationModal";
import Loader from "../../../loader/Loader";
import { Sensor, SimplifiedSensor } from "../../../../interfaces/device/Sensor";
import { CustomIconButton } from "../../../button/icon-button/CustomIconButton";

interface Props {
  selectedDevice: SwrmDevices;
  relays: RelayWithValues[];
  onClose: () => void;
}

const initialRelayRule: RelayRule = {
  Id: 0,
  Enable: true,
  DeviceId: "",
  RelayId: 0,
  Action: RelayRuleAction.On,
  At: 0,
  SensorId: 0,
  Comparison: RuleComparison.LessThan,
  Value: 0,
};

export const nonNumericalSensors: string[] = ["Door", "Water"];

const RelayRuleDialog = ({ selectedDevice, relays, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeletePending, setIsDeletePending] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [displayAddRuleModal, setDisplayAddRuleModal] =
    useState<boolean>(false);
  const [displayEditRuleModal, setDisplayEditRuleModal] =
    useState<boolean>(false);
  const [currentRelayToAddRule, setCurrentRelayToAddRule] = useState<number>(0);
  const [currentRuleToEdit, setCurrentRuleToEdit] =
    useState<RelayRule>(initialRelayRule);
  const [relayRules, setRelayRules] = useState<RelayRule[]>([]);
  const [allSensors, setAllSensors] = useState<SimplifiedSensor[]>([]);
  const [deviceDropdownOptions, setDeviceDropdownOptions] = useState<
    FilterOption[]
  >([]);
  const [currentRuleToDeleteId, setCurrentRuleToDeleteId] = useState<number>(0);
  const [deleteRuleConfirmMsg, setDeleteRuleConfirmMsg] = useState<string>("");

  const loadRelayRules = async (relayId: number) => {
    setIsLoading(true);
    const response = await relayService.getRelayRuleByRelay(relayId, dispatch);

    if (response !== null) {
      setRelayRules(response.Result);
    }

    setIsLoading(false);
  };

  const toggleAccordion = (index: number, relayId: number) => {
    loadRelayRules(relayId);
    setActiveIndex(activeIndex === index ? null : index);
    onCancel();
  };

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        Relay Rules
      </Typography>
    );
  };

  const seedSensors = async () => {
    let sensors: SimplifiedSensor[] = [];
    if (selectedDevice?.Sensors) {
      sensors = selectedDevice?.Sensors.map((x) => ({
        id: x.Id,
        value: x.Name,
        deviceId: x.DeviceId,
      }));
    }
    if (selectedDevice?.ChildDevices) {
      selectedDevice?.ChildDevices.forEach((d) => {
        d.Sensors.forEach((s) => {
          sensors.push({ id: s.Id, value: s.Name, deviceId: s.DeviceId });
        });
      });
    }
    setAllSensors(sensors);
  };

  const seedDeviceDropdownOptions = () => {
    const deviceDropdown: FilterOption[] = [];
    if (selectedDevice?.Sensors?.length) {
      deviceDropdown.push({
        label: selectedDevice?.Name || "",
        value: selectedDevice?.Id || "",
      });
    }
    selectedDevice?.ChildDevices?.filter(
      (d: SwrmDevices) => d.Sensors?.length,
    ).forEach((d: SwrmDevices) => {
      deviceDropdown.push({
        label: d.Name,
        value: d.Id,
      });
    });

    setDeviceDropdownOptions(deviceDropdown);
  };

  const findRuleSensor = (ruleSensorId: number) => {
    const sensor: SimplifiedSensor | undefined | null = allSensors.find(
      (s) => s.id == ruleSensorId,
    );

    return sensor;
  };

  const onCreateRuleClick = (relayId: number) => {
    setDisplayAddRuleModal(true);
    setCurrentRelayToAddRule(relayId);
  };

  const onEditRuleClick = (relayRule: RelayRule) => {
    setCurrentRuleToEdit(relayRule);
    setDisplayEditRuleModal(true);
  };

  const onDeleteRuleClick = (ruleId: number) => {
    const msg = `Are you sure you'd like to delete the rule? This cannot be undone.`;
    setDeleteRuleConfirmMsg(msg);
    setCurrentRuleToDeleteId(ruleId);
    setDeleteModalOpen(true);
  };

  const handleRuleAddSave = (newRelayRule: RelayRule) => {
    setRelayRules((prev) => [...prev, newRelayRule]);
    onCancel();
  };

  const handleRuleEditSave = (editedRelayRule: RelayRule) => {
    setRelayRules((prev) =>
      prev.map((item) =>
        item.Id === editedRelayRule.Id ? editedRelayRule : item,
      ),
    );
    onCancel();
  };

  const handleConfirmDelete = async () => {
    setIsDeletePending(true);
    const response = await relayService.deleteRelayRule(
      currentRuleToDeleteId,
      dispatch,
    );

    if (response !== null) {
      setRelayRules((prev) =>
        prev.filter((rule) => currentRuleToDeleteId !== rule.Id),
      );
    }

    setIsDeletePending(false);
    setCurrentRuleToDeleteId(0);
    onCancel();
  };

  const onCancel = () => {
    setDisplayAddRuleModal(false);
    setDisplayEditRuleModal(false);
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    seedSensors();
    seedDeviceDropdownOptions();
  }, []);

  return (
    <CustomModal
      isOpen={true}
      onClose={onClose}
      padding="1rem 0"
      width="60vw"
      height="65vh"
      header={cardHeader()}
    >
      {isDeletePending && (
        <Loader loadingText="Removing relay rule" isLoadingScreen />
      )}
      <div style={{ padding: "0 1rem" }}>
        <RelayAccordionContainer>
          {relays.map((relay, index) => (
            <RelayAccordionItem key={relay.Id}>
              <RelayAccordionHeader
                onClick={() => toggleAccordion(index, relay.Id)}
              >
                <Typography weight="bold">{relay.Name}</Typography>
                <Typography>
                  {activeIndex === index ? <BiMinus /> : <BiPlus />}
                </Typography>
              </RelayAccordionHeader>
              <RelayAccordionContent isOpen={activeIndex === index}>
                <SetupAddRuleTimerHeading>
                  <Typography color="primary" padding="1rem 0">
                    Create Rule
                  </Typography>
                  <CustomIconButton
                    onClick={onCreateRuleClick.bind(null, relay.Id)}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className={
                        displayAddRuleModal || displayEditRuleModal
                          ? "disabled"
                          : ""
                      }
                    />
                  </CustomIconButton>
                </SetupAddRuleTimerHeading>

                {isLoading && (
                  <Typography>Fetching available rules...</Typography>
                )}
                {!displayEditRuleModal &&
                !displayAddRuleModal &&
                relayRules.length > 0 &&
                !isLoading
                  ? relayRules.map((rule) => (
                      <RelayRuleItem
                        key={rule.Id}
                        item={rule}
                        handleEditClick={onEditRuleClick}
                        handleDeleteClick={onDeleteRuleClick}
                        handleEnableDisabled={() => null}
                        sensor={findRuleSensor(rule.SensorId)}
                      />
                    ))
                  : !isLoading &&
                    !displayEditRuleModal &&
                    !displayAddRuleModal && (
                      <Typography color="red-200">
                        No rules available
                      </Typography>
                    )}
              </RelayAccordionContent>
            </RelayAccordionItem>
          ))}
        </RelayAccordionContainer>

        {/* Add relay rules form modal */}
        {displayAddRuleModal && currentRelayToAddRule && (
          <AddRelayRuleModal
            relayId={currentRelayToAddRule}
            deviceDropdownOptions={deviceDropdownOptions}
            onCancel={onCancel}
            onSaveRuleSuccess={handleRuleAddSave}
            allSensor={allSensors}
          />
        )}

        {/* Edit relay rule form modal */}
        {displayEditRuleModal && (
          <EditRelayRuleModal
            relayRule={currentRuleToEdit}
            relayId={currentRuleToEdit.RelayId}
            deviceDropdownOptions={deviceDropdownOptions}
            onCancel={onCancel}
            onSaveRuleSuccess={handleRuleEditSave}
            allSensor={allSensors}
          />
        )}

        {/* Delete relay rule confirmation modal */}
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          setIsOpen={setDeleteModalOpen}
          onConfirm={handleConfirmDelete}
          headerTitle={"Delete Rule"}
          confirmMsg={deleteRuleConfirmMsg}
          onClose={onCancel}
          modalWidth="30vw"
          minWidth="450px"
        />
      </div>
    </CustomModal>
  );
};

export default RelayRuleDialog;
