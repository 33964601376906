import styled from "styled-components";

export const SetupNavBarWrapper = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.colors["white"]};
  display: flex;
  width: 45%;
  height: 188px;
  border: ${(props) => props.theme.border["primary-sm"]};
  border-radius: 8px;
  padding-right: 0.5rem;
  cursor: pointer;
  &:hover {
    border: ${(props) => props.theme.border["secondary-sm"]};
    & > div > svg {
      color: ${(props) => props.theme.colors["secondary"]};
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
  font-size: ${(props) => props.theme.fontSizes["6xl"]};
  color: ${(props) => props.theme.colors["primary"]};
`;
