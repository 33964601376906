import React, { useState } from "react";
import { Typography } from "../../../../components/typrography/Typography";
import ArchiveIcon from "../../../../components/icons/ArchiveIcon";
import ConfirmationModal from "../../../../components/modals/confirmation-modal/ConfirmationModal";
import { patchDeviceArchive } from "../../../../services/device/devicePatch.service";
import { useAppDispatch } from "../../../../store/hooks";
import { removeArchiveDevice } from "../../../../store/slices/devices/devicesSlice";
import { IconWrapper } from "./DeviceName.style";
import { CustomIconButton } from "../../../../components/button/icon-button/CustomIconButton";

type Props = {
  deviceId: string;
  deviceName: string;
};
export default function DeviceArchive({ deviceName, deviceId }: Props) {
  const dispatch = useAppDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState<string>("");
  const handleArchiveOnClick = () => {
    const msg = `Are you sure you'd like to archive the ${deviceName || ""}?`;
    setConfirmMsg(msg);
    setIsConfirmModalOpen(true);
  };

  const handleArchiveDevice = async () => {
    // Archive device and update device states
    const payload = {
      id: deviceId,
      archive: true,
    };

    const response = await patchDeviceArchive(payload, dispatch);

    if (response !== null) {
      setIsConfirmModalOpen(false);
      dispatch(removeArchiveDevice(deviceId));
    }
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <CustomIconButton onClick={handleArchiveOnClick}>
        <Typography variant="lg" color="white" weight="bold">
          <IconWrapper padding={"0.5rem"}>
            <ArchiveIcon size="1.25rem" />
          </IconWrapper>
        </Typography>
      </CustomIconButton>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={handleArchiveDevice}
        headerTitle={"Archive a Device"}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
    </>
  );
}
