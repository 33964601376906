import styled from "styled-components";
import { FormLabelProps } from "./FormLabel";

//styles for form label
export const CustomFormLabel = styled.label<FormLabelProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1em")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
  color: ${(props) => props.theme.colors["primary"]};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;
