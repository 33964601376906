import { FaList } from "react-icons/fa";
import {
  TfiLayoutGrid2Alt,
  TfiLayoutGrid3Alt,
  TfiLayoutGrid4Alt,
} from "react-icons/tfi";
import styled from "styled-components";

interface ThumbnailSizeIconProps {
  isActive: boolean;
}

export const ThumbnailSizeIconWrapper = styled.button<ThumbnailSizeIconProps>`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  border: ${(props) =>
    props.isActive
      ? props.theme.border["primary-sm"]
      : props.theme.border["border-sm-gray"]};
  border-radius: 3px;
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  margin-right: 5px;
  color: ${(props) => props.theme.colors["white"]};
  cursor: pointer;
  &:hover {
    border: ${(props) =>
      props.isActive
        ? props.theme.border["primary-sm"]
        : props.theme.border["border-sm-green"]};
    & > svg {
      color: ${(props) => props.theme.colors["secondary"]};
    }
  }
`;

export const StyledTfiLayoutGrid2Alt = styled(TfiLayoutGrid2Alt)`
  color: #707070;
`;

export const StyledTfiLayoutGrid3Alt = styled(TfiLayoutGrid3Alt)`
  color: #707070;
`;

export const StyledTfiLayoutGrid4Alt = styled(TfiLayoutGrid4Alt)`
  color: #707070;
`;

export const StyledFaList = styled(FaList)`
  color: #707070;
`;
