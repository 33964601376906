import React, { ChangeEvent, FocusEvent, useEffect } from "react";
import {
  ButtonPositioner,
  CustomInput,
  ErrorMsg,
  ErrorMsgWrapper,
  InputButtonWrapper,
  InputLabel,
  InputWrapper,
  SubLabel,
} from "./InputWithButton.style";
import { useField } from "formik";
import { FlexContainer } from "../../flex-container/FlexContainer";

export interface InputWithButtonProps {
  width?: string;
  height?: string;
  label?: string;
  subLabel?: string;
  placeHolder?: string;
  field?: string;
  name: string;
  padding?: string;
  margin?: string;
  fontSize?: string;
  inputWrapperWidth?: string;
  isColoredLabel?: boolean;
  value?: string | number;
  type?: string;
  disabled?: boolean;
  alignCenter?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  hasCustomErrorMessage?: boolean;
  inputMode?:
    | "decimal"
    | "email"
    | "none"
    | "numeric"
    | "search"
    | "tel"
    | "text"
    | "url";
  wrapperPadding?: string;
  children: React.ReactNode;
}

const InputWithButton: React.FC<InputWithButtonProps> = ({
  width,
  height,
  label,
  placeHolder,
  subLabel,
  name,
  padding,
  margin,
  fontSize,
  inputWrapperWidth,
  isColoredLabel,
  value,
  type,
  disabled,
  alignCenter,
  onChange,
  onBlur,
  hasCustomErrorMessage,
  inputMode,
  wrapperPadding,
  children,
}) => {
  const [field, meta] = useField(name);

  return (
    <InputWrapper
      padding={wrapperPadding || padding}
      fontSize={fontSize}
      width={inputWrapperWidth}
    >
      {label ? (
        <InputLabel htmlFor={name} isColoredLabel={isColoredLabel}>
          {label}
          <SubLabel>{subLabel ? subLabel : ""}</SubLabel>
        </InputLabel>
      ) : (
        ""
      )}
      <InputButtonWrapper
        style={{
          display: "flex",
          alignContent: "center",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <CustomInput
          disabled={!!disabled}
          alignCenter={alignCenter}
          id={name}
          name={name}
          placeholder={placeHolder}
          width={width}
          height={height}
          padding={padding}
          margin={margin}
          onBlur={onBlur}
          onChange={onChange || field.onChange}
          inputMode={inputMode}
          value={
            typeof field.value === "string"
              ? field.value.trimStart()
              : field.value
          }
          type={type}
          onKeyDown={(e) => {
            if (type === "number" && ["e", "E", "+"].includes(e.key)) {
              e.preventDefault();
            }
          }}
        />
        <ButtonPositioner>{children}</ButtonPositioner>
      </InputButtonWrapper>
      <ErrorMsgWrapper fontSize={fontSize}>
        {meta.touched && meta.error && !hasCustomErrorMessage && (
          <ErrorMsg style={{}}>{meta.error}</ErrorMsg>
        )}
      </ErrorMsgWrapper>
    </InputWrapper>
  );
};

export default InputWithButton;
